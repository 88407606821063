import axios from "axios";

export const Get = async (url, apiKey, token, additionalHeaders) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (apiKey) headers["x-api-key"] = apiKey;
  if (token) headers["Authorization"] = token;
  if (additionalHeaders && Object.keys(additionalHeaders).length > 0)
    Object.keys(additionalHeaders).forEach(header => {
      headers[header] = additionalHeaders[header];
    });

  return axios
    .get(url, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const message = err.response
        ? err.response.data
          ? err.response.data.message
          : err.message
        : err.message;
      throw message;
    });
};

export const Post = async (url, body, apiKey, token, additionalHeaders) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (apiKey) headers["x-api-key"] = apiKey;
  if (token) headers["Authorization"] = token;

  if (additionalHeaders && Object.keys(additionalHeaders).length > 0)
    Object.keys(additionalHeaders).forEach(header => {
      headers[header] = additionalHeaders[header];
    });

  return axios
    .post(url, body, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const message = err.response
        ? err.response.data
          ? err.response.data.message
          : err.message
        : err.message;
      throw message;
    });
};

export const Put = async (url, body, apiKey, token, additionalHeaders) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (apiKey) headers["x-api-key"] = apiKey;
  if (token) headers["Authorization"] = token;
  if (additionalHeaders && Object.keys(additionalHeaders).length > 0)
    Object.keys(additionalHeaders).forEach(header => {
      headers[header] = additionalHeaders[header];
    });

  return axios
    .put(url, body, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const message = err.response
        ? err.response.data
          ? err.response.data.message
          : err.message
        : err.message;
      throw message;
    });
};

export const Delete = async (url, apiKey, token, additionalHeaders) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (apiKey) headers["x-api-key"] = apiKey;
  if (token) headers["Authorization"] = token;
  if (additionalHeaders && Object.keys(additionalHeaders).length > 0)
    Object.keys(additionalHeaders).forEach(header => {
      headers[header] = additionalHeaders[header];
    });

  return axios
    .delete(url, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const message = err.response
        ? err.response.data
          ? err.response.data.message
          : err.message
        : err.message;
      throw message;
    });
};
