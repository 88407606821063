import React from "react";
import { Navbar } from "reactstrap";

const Header = () => {
    return (
        <Navbar className="max-header">
            <div className="logo">
                <img className="logo-img"
                    id="logo-max"
                    alt="logo-max" />
            </div>
        </Navbar >
    );
};

export default Header;
