import React, { useState } from "react";
import i18next from "i18next";
import { isValidEmail } from "../../../helpers/input";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

const Email = (props) => {
  const [isDirty, setIsDirty] = useState(false);
  return (
    <FormGroup style={{
      marginBottom: "1rem"
    }} key={"form" + props.field} controlId={props.field}>
      {props.title && (
        <Label>
          {" "}
          {props.isRequired ? props.title + "*" : props.title}
        </Label>
      )}
      <Input
        style={{ fontSize: "var(--theme-font-size)" }}
        key={props.field}
        required={props.isRequired}
        type="email"
        placeholder={props.placeholder}
        value={props.value ? props.value : ""}
        onChange={(e) => {
          setIsDirty(true);
          props.handleChange(props.field, e.target.value);
        }}
        disabled={props.isReadOnly}
        invalid={
          isDirty &&
          ((props.isRequired && !props.value) || !isValidEmail(props.value))
        }
      />
      <FormFeedback type="invalid">
        {props.isRequired && !props.value && i18next.t("required field")}
        {props.value && i18next.t("enter a valid email address")}
      </FormFeedback>
    </FormGroup>
  );
};

export default Email;
