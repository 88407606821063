import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import i18next from "i18next";
import { useHistory, Link } from "react-router-dom";
import * as paths from "../../../constants/paths";
import { addMessageListener, sendACKMessage } from "../../common";
import { getPageTitle } from "../../../helpers/layout";

const Interstitial_Ios = (props) => {
  let history = useHistory();
  const brand = useSelector((state) => state.form.brand);

  useEffect(() => {
    addMessageListener();
    sendACKMessage();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (brand)
      document.title = getPageTitle(brand, paths.type_interstitial);
    // eslint-disable-next-line
  }, [brand]);

  const p1 = i18next.t(
    "If you purchased your subscription from an app store, please contact the app store to cancel."
  );
  const p2 = i18next.t(
    "If you purchased your subscription from us, you can either cancel it yourself directly in your account or use this #linkToWebform."
  );
  return (
    <div className="body">
      <div className="center-content">
        <h1 className="title">{i18next.t("Terminate contracts")}</h1>
        <p className="paragraph paragraph-padding">
          {p1}
          <br />
          <br />
          <br />
          {p2.split("#linkToWebform")[0]}
          <u>
            <Link
              style={{ textDecoration: "revert" }}
              onClick={() =>
                history.push(
                  props.location.pathname.replace(
                    paths.type_interstitial,
                    paths.type_form
                  )
                )
              }
            >
              {" "}
              {i18next.t("form")}
            </Link>
          </u>
          {p2.split("#linkToWebform")[1]}
        </p>
      </div>
    </div>
  );
};

export default Interstitial_Ios;
