import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { dispatch } from "../store";
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";
import * as paths from "../constants/paths";
import Loader from "../components/Loading/Loader";
import { setLanguage, setFolder } from "../store/actions/form";

import GermanyEurosportPlayer from "./eurosportPlayer/germany/main";
import FranceEurosportPlayer from "./eurosportPlayer/france/main";
import GermanyDiscoveryPlus from "./discoveryplus/germany/main";
import FranceDiscoveryPlus from "./discoveryplus/france/main";
import CanadaDiscoveryPlus from "./discoveryplus/canada/main";
import GermanyEurosport from "./eurosport/germany/main";
import FranceEurosport from "./eurosport/france/main";
import FranceMax from "./max/france/main";
import FranceGolfTv from "./golftv/france/main";
import GermanyGolfTv from "./golftv/germany/main";
import FranceGCN from "./gcn/france/main";
import GermanyGCN from "./gcn/germany/main";
import { fr, en, enGB, folderMappingLanguage } from '../constants/label';

const App = (props) => {
    const locale = useSelector((state) => state.form.local);

    useEffect(() => {
        const userLanguage = window.location.href.endsWith("/" + en) ? en : window.location.href.endsWith("/" + fr) ? fr : "";

        let folderLanguage = enGB;
        let folder = null;
        [paths.subDomainGermany, paths.subDomainFrance, paths.subDomainCanada].forEach((key) => {
            // eslint-disable-next-line
            const regex = `^.*\/${key}\/.*$`
            if (window.location.href.match(regex)) {
                folder = key;
                folderLanguage = folderMappingLanguage[key + userLanguage] ?? folderMappingLanguage[key];
            }
        });

        dispatch(setLanguage(folderLanguage));
        dispatch(setFolder(folder));
        // eslint-disable-next-line
    }, []);

    return (
        <Loader>
            <I18nextProvider i18n={i18n(locale)}>
                <Router history={props.hist}>
                    <Switch>
                        <Route path={"/" + paths.subDomainGermany + "/" + paths.brand_eurosportPlayer} render={(props) => <GermanyEurosportPlayer {...props} />} />
                        <Route path={"/" + paths.subDomainGermany + "/" + paths.brand_dplus} render={(props) => <GermanyDiscoveryPlus {...props} />} />
                        <Route path={"/" + paths.subDomainGermany + "/" + paths.brand_golftv} render={(props) => <GermanyGolfTv {...props} />} />
                        <Route path={"/" + paths.subDomainGermany + "/" + paths.brand_eurosport} render={(props) => <GermanyEurosport {...props} />} />
                        <Route path={"/" + paths.subDomainGermany + "/" + paths.brand_gcn} render={(props) => <GermanyGCN {...props} />} />
                        <Route path={"/" + paths.subDomainFrance + "/" + paths.brand_eurosportPlayer} render={(props) => <FranceEurosportPlayer {...props} />} />
                        <Route path={"/" + paths.subDomainFrance + "/" + paths.brand_dplus} render={(props) => <FranceDiscoveryPlus {...props} />} />
                        <Route path={"/" + paths.subDomainFrance + "/" + paths.brand_golftv} render={(props) => <FranceGolfTv {...props} />} />
                        <Route path={"/" + paths.subDomainFrance + "/" + paths.brand_eurosport} render={(props) => <FranceEurosport {...props} />} />
                        <Route path={"/" + paths.subDomainFrance + "/" + paths.brand_max} render={(props) => <FranceMax {...props} />} />
                        <Route path={"/" + paths.subDomainFrance + "/" + paths.brand_gcn} render={(props) => <FranceGCN {...props} />} />
                        <Route path={"/" + paths.subDomainCanada + "/" + paths.brand_dplus} render={(props) => <CanadaDiscoveryPlus {...props} />} />
                    </Switch>
                </Router>
            </I18nextProvider>
        </Loader>)
}

export default App;