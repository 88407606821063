import { getDateTime, getTime } from "./date";

export const combinePath = (...paths) => {
  const path = require("path");
  return path.join.apply(path, paths);
};

export const getFileName = (fullPath) => {
  return fullPath.split("\\").pop().split("/").pop();
};

export const getPrefixFilesInfoAndSort = (files) => {
  const filesInfo = files.map((file) => {
    return {
      key: getFileName(file.Key),
      size: formatBytes(file.Size),
      lastModified: getDateTime(file.LastModified), //"2021:01:31 00:00:00",
    };
  });

  return filesInfo
    .sort((a, b) => getTime(b.lastModified) - getTime(a.lastModified))
    .filter((fileInfo) => fileInfo.key);
};

export const triggerDownload = (data) => {
  const blob = new Blob([data.content], {
    type: data.type ? data.type : "text/html",
  });
  const link = document.createElement("a");
  link.style.display = "none";
  document.body.appendChild(link);
  link.href = URL.createObjectURL(blob);
  link.download = data.key;
  link.click();
};

export const formatBytes = (bytes, decimals = 1) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};


export const fileTypeCheck = (fileInfo, acceptedFileType = ["text/plain", "application/vnd.ms-excel", "text/csv"]) => {
  return (fileInfo && !acceptedFileType.includes(fileInfo.type)) ? false : true;
};

export const imageToBase64 = async (file) => {
  return await getBase64(file);
};

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};