import React from "react";
import { FormGroup, Label } from "reactstrap";

export default function customLabel(props) {
  const { field, title } = props;
  return (
    <FormGroup key={"form" + field} controlid={field}>
      {title && <Label>{title}</Label>}
    </FormGroup>
  );
}
