import * as subjects from "./constants/subjects";
import * as paths from "./constants/paths";
import germany_form from "views/discoveryplus/germany/form";
import germany_interstitial_android from "views/discoveryplus/germany/interstitial_android";
import germany_interstitial_ios from "views/discoveryplus/germany/interstitial_ios";
import germany_confirmation from "views/discoveryplus/germany/confirmation";
import france_disco_form from "views/discoveryplus/france/form";
import france_eurosport_form from "views/eurosport/france/form";
import france_interstitial_android from "views/discoveryplus/france/interstitial_android";
import france_interstitial_ios from "views/discoveryplus/france/interstitial_ios";
import france_confirmation from "views/eurosport/france/confirmation";
import france_max_form from "views/max/france/form";
import france_max_interstitial_android from "views/max/france/interstitial_android";
import france_max_interstitial_ios from "views/max/france/interstitial_ios";
import france_max_confirmation from "views/max/france/confirmation";
import canada_form from "views/discoveryplus/canada/form";
import canada_interstitial_android from "views/discoveryplus/canada/interstitial_android";
import canada_interstitial_ios from "views/discoveryplus/canada/interstitial_ios";
import canada_confirmation from "views/discoveryplus/canada/confirmation";

const countries = {
  [paths.subDomainGermany]: {
    form: {
      [paths.brand_eurosport]: germany_form,
      [paths.brand_dplus]: germany_form,
      [paths.brand_eurosportPlayer]: germany_form,
      [paths.brand_golftv]: germany_form,
      [paths.brand_gcn]: germany_form
    },
    interstitial_android: {
      [paths.brand_eurosport]: germany_interstitial_android,
      [paths.brand_dplus]: germany_interstitial_android,
      [paths.brand_eurosportPlayer]: germany_interstitial_android,
      [paths.brand_golftv]: germany_interstitial_android,
      [paths.brand_gcn]: germany_interstitial_android
    },
    interstitial_ios: {
      [paths.brand_eurosport]: germany_interstitial_ios,
      [paths.brand_dplus]: germany_interstitial_ios,
      [paths.brand_eurosportPlayer]: germany_interstitial_ios,
      [paths.brand_golftv]: germany_interstitial_ios,
      [paths.brand_gcn]: germany_interstitial_ios
    },
    confirmation: {
      [paths.brand_eurosport]: germany_confirmation,
      [paths.brand_dplus]: germany_confirmation,
      [paths.brand_eurosportPlayer]: germany_confirmation,
      [paths.brand_golftv]: germany_confirmation,
      [paths.brand_gcn]: germany_confirmation
    },
  },
  [paths.subDomainFrance]: {
    form: {
      [paths.brand_eurosport]: france_eurosport_form,
      [paths.brand_dplus]: france_disco_form,
      [paths.brand_eurosportPlayer]: france_disco_form,
      [paths.brand_golftv]: france_disco_form,
      [paths.brand_gcn]: france_eurosport_form,
      [paths.brand_max]: france_max_form
    },
    interstitial_android: {
      [paths.brand_eurosport]: france_interstitial_android,
      [paths.brand_dplus]: france_interstitial_android,
      [paths.brand_eurosportPlayer]: france_interstitial_android,
      [paths.brand_golftv]: france_interstitial_android,
      [paths.brand_gcn]: france_interstitial_android,
      [paths.brand_max]: france_max_interstitial_android
    },
    interstitial_ios: {
      [paths.brand_eurosport]: france_interstitial_ios,
      [paths.brand_dplus]: france_interstitial_ios,
      [paths.brand_eurosportPlayer]: france_interstitial_ios,
      [paths.brand_golftv]: france_interstitial_ios,
      [paths.brand_gcn]: france_interstitial_ios,
      [paths.brand_max]: france_max_interstitial_ios
    },
    confirmation: {
      [paths.brand_eurosport]: france_confirmation,
      [paths.brand_dplus]: france_confirmation,
      [paths.brand_eurosportPlayer]: france_confirmation,
      [paths.brand_golftv]: france_confirmation,
      [paths.brand_gcn]: france_confirmation,
      [paths.brand_max]: france_max_confirmation
    },
  },
  [paths.subDomainCanada]: {
    form: {
      [paths.brand_eurosport]: canada_form,
      [paths.brand_dplus]: canada_form,
      [paths.brand_eurosportPlayer]: canada_form,
      [paths.brand_golftv]: canada_form,
      [paths.brand_gcn]: canada_form
    },
    interstitial_android: {
      [paths.brand_eurosport]: canada_interstitial_android,
      [paths.brand_dplus]: canada_interstitial_android,
      [paths.brand_eurosportPlayer]: canada_interstitial_android,
      [paths.brand_golftv]: canada_interstitial_android,
      [paths.brand_gcn]: canada_interstitial_android
    },
    interstitial_ios: {
      [paths.brand_eurosport]: canada_interstitial_ios,
      [paths.brand_dplus]: canada_interstitial_ios,
      [paths.brand_eurosportPlayer]: canada_interstitial_ios,
      [paths.brand_golftv]: canada_interstitial_ios,
      [paths.brand_gcn]: canada_interstitial_ios
    },
    confirmation: {
      [paths.brand_eurosport]: canada_confirmation,
      [paths.brand_dplus]: canada_confirmation,
      [paths.brand_eurosportPlayer]: canada_confirmation,
      [paths.brand_golftv]: canada_confirmation,
      [paths.brand_gcn]: canada_confirmation
    }
  },
}

export const eurosportPlayerRoutes = (country) => [
  {
    id: subjects.EUROSPORT_PLAYER_ANDROIDAPP_INTERSTITIAL,
    paths: paths.eurosportPlayer_android_app_interstitial(country),
    component: countries[country].interstitial_android[paths.brand_eurosportPlayer],
  },
  {
    id: subjects.EUROSPORT_PLAYER_IOSAPP_INTERSTITIAL,
    paths: paths.eurosportPlayer_ios_app_interstitial(country),
    component: countries[country].interstitial_ios[paths.brand_eurosportPlayer],
  },
  {
    id: subjects.EUROSPORT_PLAYER_FORM,
    paths: [paths.eurosportPlayer_website_form(country), paths.eurosportPlayer_ios_app_form(country), paths.eurosportPlayer_android_app_form(country), paths.eurosportPlayer_connected_tv_form(country)],
    component: countries[country].form[paths.brand_eurosportPlayer],

  },
  {
    id: subjects.EUROSPORT_PLAYER_CONFIRMATION,
    paths: [paths.eurosportPlayer_website_confirmation(country), paths.eurosportPlayer_ios_app_confirmation(country), paths.eurosportPlayer_android_app_confirmation(country), paths.eurosportPlayer_connected_tv_confirmation(country)],
    component: countries[country].confirmation[paths.brand_eurosportPlayer],
  },
]

export const dplusRoutes = (country) => [
  {
    id: subjects.DPLUS_ANDROIDAPP_INTERSTITIAL,
    paths: paths.dplus_android_app_interstitial(country),
    component: countries[country].interstitial_android[paths.brand_dplus],
  },
  {
    id: subjects.DPLUS_IOSAPP_INTERSTITIAL,
    paths: paths.dplus_ios_app_interstitial(country),
    component: countries[country].interstitial_ios[paths.brand_dplus],
  },
  {
    id: subjects.DPLUS_FORM,
    paths: [paths.dplus_website_form(country), paths.dplus_ios_app_form(country), paths.dplus_android_app_form(country), paths.dplus_connected_tv_form(country)],
    component: countries[country].form[paths.brand_dplus],
  },
  {
    id: subjects.DPLUS_CONFIRMATION,
    paths: [paths.dplus_website_confirmation(country), paths.dplus_ios_app_confirmation(country), paths.dplus_android_app_confirmation(country), paths.dplus_connected_tv_confirmation(country)],
    component: countries[country].confirmation[paths.brand_dplus],
  },
]

export const gtvRoutes = (country) => [
  {
    id: subjects.GOLFTV_ANDROIDAPP_INTERSTITIAL,
    paths: paths.golftv_android_app_interstitial(country),
    component: countries[country].interstitial_android[paths.brand_golftv],
  },
  {
    id: subjects.GOLFTV_IOSAPP_INTERSTITIAL,
    paths: paths.golftv_ios_app_interstitial(country),
    component: countries[country].interstitial_ios[paths.brand_golftv],
  },
  {
    id: subjects.GOLFTV_FORM,
    paths: [paths.golftv_website_form(country), paths.golftv_ios_app_form(country), paths.golftv_android_app_form(country), paths.golftv_connected_tv_form(country)],
    component: countries[country].form[paths.brand_golftv],
  },
  {
    id: subjects.GOLFTV_CONFIRMATION,
    paths: [paths.golftv_website_confirmation(country), paths.golftv_ios_app_confirmation(country), paths.golftv_android_app_confirmation(country), paths.golftv_connected_tv_confirmation(country)],
    component: countries[country].confirmation[paths.brand_golftv],
  },
]

export const eurosportRoutes = (country) => [
  {
    id: subjects.EUROSPORT_ANDROIDAPP_INTERSTITIAL,
    paths: paths.eurosport_android_app_interstitial(country),
    component: countries[country].interstitial_android[paths.brand_eurosport],
  },
  {
    id: subjects.EUROSPORT_IOSAPP_INTERSTITIAL,
    paths: paths.eurosport_ios_app_interstitial(country),
    component: countries[country].interstitial_ios[paths.brand_eurosport],
  },
  {
    id: subjects.EUROSPORT_FORM,
    paths: [paths.eurosport_website_form(country), paths.eurosport_ios_app_form(country), paths.eurosport_android_app_form(country), paths.eurosport_connected_tv_form(country)],
    component: countries[country].form[paths.brand_eurosport],
  },
  {
    id: subjects.EUROSPORT_CONFIRMATION,
    paths: [paths.eurosport_website_confirmation(country), paths.eurosport_ios_app_confirmation(country), paths.eurosport_android_app_confirmation(country), paths.eurosport_connected_tv_confirmation(country)],
    component: countries[country].confirmation[paths.brand_eurosport],
  },
]

export const maxRoutes = (country) => [
  {
    id: subjects.MAX_ANDROIDAPP_INTERSTITIAL,
    paths: paths.max_android_app_interstitial(country),
    component: countries[country].interstitial_android[paths.brand_max],
  },
  {
    id: subjects.MAX_IOSAPP_INTERSTITIAL,
    paths: paths.max_ios_app_interstitial(country),
    component: countries[country].interstitial_ios[paths.brand_max],
  },
  {
    id: subjects.MAX_FORM,
    paths: [paths.max_website_form(country), paths.max_ios_app_form(country), paths.max_android_app_form(country), paths.max_connected_tv_form(country)],
    component: countries[country].form[paths.brand_max],
  },
  {
    id: subjects.MAX_CONFIRMATION,
    paths: [paths.max_website_confirmation(country), paths.max_ios_app_confirmation(country), paths.max_android_app_confirmation(country), paths.max_connected_tv_confirmation(country)],
    component: countries[country].confirmation[paths.brand_max],
  },
]

export const gcnRoutes = (country) => [
  {
    id: subjects.GCN_ANDROIDAPP_INTERSTITIAL,
    paths: paths.gcn_android_app_interstitial(country),
    component: countries[country].interstitial_android[paths.brand_gcn],
  },
  {
    id: subjects.GCN_IOSAPP_INTERSTITIAL,
    paths: paths.gcn_ios_app_interstitial(country),
    component: countries[country].interstitial_ios[paths.brand_gcn],
  },
  {
    id: subjects.GCN_FORM,
    paths: [paths.gcn_website_form(country), paths.gcn_ios_app_form(country), paths.gcn_android_app_form(country), paths.gcn_connected_tv_form(country)],
    component: countries[country].form[paths.brand_gcn],
  },
  {
    id: subjects.GCN_CONFIRMATION,
    paths: [paths.gcn_website_confirmation(country), paths.gcn_ios_app_confirmation(country), paths.gcn_android_app_confirmation(country), paths.gcn_connected_tv_confirmation(country)],
    component: countries[country].confirmation[paths.brand_gcn],
  },
]

