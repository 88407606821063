import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../header";
// import Footer from "./footer.js";
import { Route, Switch } from "react-router-dom";
import { setBrand, setPlatform } from "../../../store/actions/form";
import { getFavicon, getPlatform } from "../../../helpers/layout";
import { gtvRoutes } from "../../../routes";
import * as paths from "../../../constants/paths";
import { GTV } from "../../../constants/label";

const Main = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const favicon = getFavicon();
        favicon.href = "/favicon_Gtv.png";

        dispatch(setBrand(GTV));
        dispatch(setPlatform(getPlatform(window.location.href)));
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <Header />
            <Switch>
                {gtvRoutes(paths.subDomainFrance).map((route, key) =>
                    <Route
                        path={route.paths}
                        component={route.component}
                        key={key}
                    />)}
                {/* <Redirect to={paths.golftv_website_form(paths.layout)} /> */}
            </Switch>
            {/* <Footer /> */}
        </div>
    );
};

export default Main;
