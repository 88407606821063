import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

export default function Description(props) {
  const {
    field,
    title,
    value,
    maxLength,
    isRequired,
    isReadOnly,
    placeholder,
    handleChange,
  } = props;
  return (
    <FormGroup key={"form" + field} controlid={field}>
      <Label> {isRequired ? title + "*" : title}</Label>
      <Input
        rows="5"
        maxLength={maxLength}
        key={field}
        required={isRequired}
        type="textarea"
        value={value ? value : ""}
        onChange={(e) => handleChange(field, e.target.value)}
        disabled={isReadOnly}
        placeholder={placeholder}
      />
    </FormGroup>
  );
}
