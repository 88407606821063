export const subDomainGermany = "fcca";
export const subDomainFrance = "fcc215";
export const subDomainCanada = "canada-cancellation";
export const brand_eurosportPlayer = "eurosportplayer";
export const brand_dplus = "dplus";
export const brand_golftv = "golftv";
export const brand_eurosport = "eurosport";
export const brand_max = "max";
export const brand_gcn = "globalcyclingnetwork";

export const platefrom_website = "website";
export const platefrom_ios_app = "ios-app";
export const platefrom_android_app = "android-app";
export const platefrom_connected_tv = "connected-tv";

export const type_interstitial = "interstitial";
export const type_form = "form";
export const type_confirmation = "confirmation";

export const eurosportPlayer_website_interstitial = (layoutType) => "/" + layoutType + "/" + brand_eurosportPlayer + "/" + platefrom_website + "/" + type_interstitial;
export const eurosportPlayer_website_form = (layoutType) => "/" + layoutType + "/" + brand_eurosportPlayer + "/" + platefrom_website + "/" + type_form;
export const eurosportPlayer_website_confirmation = (layoutType) => "/" + layoutType + "/" + brand_eurosportPlayer + "/" + platefrom_website + "/" + type_confirmation;
export const eurosportPlayer_ios_app_interstitial = (layoutType) => "/" + layoutType + "/" + brand_eurosportPlayer + "/" + platefrom_ios_app + "/" + type_interstitial;
export const eurosportPlayer_ios_app_form = (layoutType) => "/" + layoutType + "/" + brand_eurosportPlayer + "/" + platefrom_ios_app + "/" + type_form;
export const eurosportPlayer_ios_app_confirmation = (layoutType) => "/" + layoutType + "/" + brand_eurosportPlayer + "/" + platefrom_ios_app + "/" + type_confirmation;
export const eurosportPlayer_android_app_interstitial = (layoutType) => "/" + layoutType + "/" + brand_eurosportPlayer + "/" + platefrom_android_app + "/" + type_interstitial;
export const eurosportPlayer_android_app_form = (layoutType) => "/" + layoutType + "/" + brand_eurosportPlayer + "/" + platefrom_android_app + "/" + type_form;
export const eurosportPlayer_android_app_confirmation = (layoutType) => "/" + layoutType + "/" + brand_eurosportPlayer + "/" + platefrom_android_app + "/" + type_confirmation;
export const eurosportPlayer_connected_tv_interstitial = (layoutType) => "/" + layoutType + "/" + brand_eurosportPlayer + "/" + platefrom_connected_tv + "/" + type_interstitial;
export const eurosportPlayer_connected_tv_form = (layoutType) => "/" + layoutType + "/" + brand_eurosportPlayer + "/" + platefrom_connected_tv + "/" + type_form;
export const eurosportPlayer_connected_tv_confirmation = (layoutType) => "/" + layoutType + "/" + brand_eurosportPlayer + "/" + platefrom_connected_tv + "/" + type_confirmation;

export const dplus_website_interstitial = (layoutType) => "/" + layoutType + "/" + brand_dplus + "/" + platefrom_website + "/" + type_interstitial;
export const dplus_website_form = (layoutType) => "/" + layoutType + "/" + brand_dplus + "/" + platefrom_website + "/" + type_form;
export const dplus_website_confirmation = (layoutType) => "/" + layoutType + "/" + brand_dplus + "/" + platefrom_website + "/" + type_confirmation;
export const dplus_ios_app_interstitial = (layoutType) => "/" + layoutType + "/" + brand_dplus + "/" + platefrom_ios_app + "/" + type_interstitial;
export const dplus_ios_app_form = (layoutType) => "/" + layoutType + "/" + brand_dplus + "/" + platefrom_ios_app + "/" + type_form;
export const dplus_ios_app_confirmation = (layoutType) => "/" + layoutType + "/" + brand_dplus + "/" + platefrom_ios_app + "/" + type_confirmation;
export const dplus_android_app_interstitial = (layoutType) => "/" + layoutType + "/" + brand_dplus + "/" + platefrom_android_app + "/" + type_interstitial;
export const dplus_android_app_form = (layoutType) => "/" + layoutType + "/" + brand_dplus + "/" + platefrom_android_app + "/" + type_form;
export const dplus_android_app_confirmation = (layoutType) => "/" + layoutType + "/" + brand_dplus + "/" + platefrom_android_app + "/" + type_confirmation;
export const dplus_connected_tv_interstitial = (layoutType) => "/" + layoutType + "/" + brand_dplus + "/" + platefrom_connected_tv + "/" + type_interstitial;
export const dplus_connected_tv_form = (layoutType) => "/" + layoutType + "/" + brand_dplus + "/" + platefrom_connected_tv + "/" + type_form;
export const dplus_connected_tv_confirmation = (layoutType) => "/" + layoutType + "/" + brand_dplus + "/" + platefrom_connected_tv + "/" + type_confirmation;

export const golftv_website_interstitial = (layoutType) => "/" + layoutType + "/" + brand_golftv + "/" + platefrom_website + "/" + type_interstitial;
export const golftv_website_form = (layoutType) => "/" + layoutType + "/" + brand_golftv + "/" + platefrom_website + "/" + type_form;
export const golftv_website_confirmation = (layoutType) => "/" + layoutType + "/" + brand_golftv + "/" + platefrom_website + "/" + type_confirmation;
export const golftv_ios_app_interstitial = (layoutType) => "/" + layoutType + "/" + brand_golftv + "/" + platefrom_ios_app + "/" + type_interstitial;
export const golftv_ios_app_form = (layoutType) => "/" + layoutType + "/" + brand_golftv + "/" + platefrom_ios_app + "/" + type_form;
export const golftv_ios_app_confirmation = (layoutType) => "/" + layoutType + "/" + brand_golftv + "/" + platefrom_ios_app + "/" + type_confirmation;
export const golftv_android_app_interstitial = (layoutType) => "/" + layoutType + "/" + brand_golftv + "/" + platefrom_android_app + "/" + type_interstitial;
export const golftv_android_app_form = (layoutType) => "/" + layoutType + "/" + brand_golftv + "/" + platefrom_android_app + "/" + type_form;
export const golftv_android_app_confirmation = (layoutType) => "/" + layoutType + "/" + brand_golftv + "/" + platefrom_android_app + "/" + type_confirmation;
export const golftv_connected_tv_interstitial = (layoutType) => "/" + layoutType + "/" + brand_golftv + "/" + platefrom_connected_tv + "/" + type_interstitial;
export const golftv_connected_tv_form = (layoutType) => "/" + layoutType + "/" + brand_golftv + "/" + platefrom_connected_tv + "/" + type_form;
export const golftv_connected_tv_confirmation = (layoutType) => "/" + layoutType + "/" + brand_golftv + "/" + platefrom_connected_tv + "/" + type_confirmation;

export const eurosport_website_interstitial = (layoutType) => "/" + layoutType + "/" + brand_eurosport + "/" + platefrom_website + "/" + type_interstitial;
export const eurosport_website_form = (layoutType) => "/" + layoutType + "/" + brand_eurosport + "/" + platefrom_website + "/" + type_form;
export const eurosport_website_confirmation = (layoutType) => "/" + layoutType + "/" + brand_eurosport + "/" + platefrom_website + "/" + type_confirmation;
export const eurosport_ios_app_interstitial = (layoutType) => "/" + layoutType + "/" + brand_eurosport + "/" + platefrom_ios_app + "/" + type_interstitial;
export const eurosport_ios_app_form = (layoutType) => "/" + layoutType + "/" + brand_eurosport + "/" + platefrom_ios_app + "/" + type_form;
export const eurosport_ios_app_confirmation = (layoutType) => "/" + layoutType + "/" + brand_eurosport + "/" + platefrom_ios_app + "/" + type_confirmation;
export const eurosport_android_app_interstitial = (layoutType) => "/" + layoutType + "/" + brand_eurosport + "/" + platefrom_android_app + "/" + type_interstitial;
export const eurosport_android_app_form = (layoutType) => "/" + layoutType + "/" + brand_eurosport + "/" + platefrom_android_app + "/" + type_form;
export const eurosport_android_app_confirmation = (layoutType) => "/" + layoutType + "/" + brand_eurosport + "/" + platefrom_android_app + "/" + type_confirmation;
export const eurosport_connected_tv_interstitial = (layoutType) => "/" + layoutType + "/" + brand_eurosport + "/" + platefrom_connected_tv + "/" + type_interstitial;
export const eurosport_connected_tv_form = (layoutType) => "/" + layoutType + "/" + brand_eurosport + "/" + platefrom_connected_tv + "/" + type_form;
export const eurosport_connected_tv_confirmation = (layoutType) => "/" + layoutType + "/" + brand_eurosport + "/" + platefrom_connected_tv + "/" + type_confirmation;

export const max_website_interstitial = (layoutType) => "/" + layoutType + "/" + brand_max + "/" + platefrom_website + "/" + type_interstitial;
export const max_website_form = (layoutType) => "/" + layoutType + "/" + brand_max + "/" + platefrom_website + "/" + type_form;
export const max_website_confirmation = (layoutType) => "/" + layoutType + "/" + brand_max + "/" + platefrom_website + "/" + type_confirmation;
export const max_ios_app_interstitial = (layoutType) => "/" + layoutType + "/" + brand_max + "/" + platefrom_ios_app + "/" + type_interstitial;
export const max_ios_app_form = (layoutType) => "/" + layoutType + "/" + brand_max + "/" + platefrom_ios_app + "/" + type_form;
export const max_ios_app_confirmation = (layoutType) => "/" + layoutType + "/" + brand_max + "/" + platefrom_ios_app + "/" + type_confirmation;
export const max_android_app_interstitial = (layoutType) => "/" + layoutType + "/" + brand_max + "/" + platefrom_android_app + "/" + type_interstitial;
export const max_android_app_form = (layoutType) => "/" + layoutType + "/" + brand_max + "/" + platefrom_android_app + "/" + type_form;
export const max_android_app_confirmation = (layoutType) => "/" + layoutType + "/" + brand_max + "/" + platefrom_android_app + "/" + type_confirmation;
export const max_connected_tv_interstitial = (layoutType) => "/" + layoutType + "/" + brand_max + "/" + platefrom_connected_tv + "/" + type_interstitial;
export const max_connected_tv_form = (layoutType) => "/" + layoutType + "/" + brand_max + "/" + platefrom_connected_tv + "/" + type_form;
export const max_connected_tv_confirmation = (layoutType) => "/" + layoutType + "/" + brand_max + "/" + platefrom_connected_tv + "/" + type_confirmation;

export const gcn_website_interstitial = (layoutType) => "/" + layoutType + "/" + brand_gcn + "/" + platefrom_website + "/" + type_interstitial;
export const gcn_website_form = (layoutType) => "/" + layoutType + "/" + brand_gcn + "/" + platefrom_website + "/" + type_form;
export const gcn_website_confirmation = (layoutType) => "/" + layoutType + "/" + brand_gcn + "/" + platefrom_website + "/" + type_confirmation;
export const gcn_ios_app_interstitial = (layoutType) => "/" + layoutType + "/" + brand_gcn + "/" + platefrom_ios_app + "/" + type_interstitial;
export const gcn_ios_app_form = (layoutType) => "/" + layoutType + "/" + brand_gcn + "/" + platefrom_ios_app + "/" + type_form;
export const gcn_ios_app_confirmation = (layoutType) => "/" + layoutType + "/" + brand_gcn + "/" + platefrom_ios_app + "/" + type_confirmation;
export const gcn_android_app_interstitial = (layoutType) => "/" + layoutType + "/" + brand_gcn + "/" + platefrom_android_app + "/" + type_interstitial;
export const gcn_android_app_form = (layoutType) => "/" + layoutType + "/" + brand_gcn + "/" + platefrom_android_app + "/" + type_form;
export const gcn_android_app_confirmation = (layoutType) => "/" + layoutType + "/" + brand_gcn + "/" + platefrom_android_app + "/" + type_confirmation;
export const gcn_connected_tv_interstitial = (layoutType) => "/" + layoutType + "/" + brand_gcn + "/" + platefrom_connected_tv + "/" + type_interstitial;
export const gcn_connected_tv_form = (layoutType) => "/" + layoutType + "/" + brand_gcn + "/" + platefrom_connected_tv + "/" + type_form;
export const gcn_connected_tv_confirmation = (layoutType) => "/" + layoutType + "/" + brand_gcn + "/" + platefrom_connected_tv + "/" + type_confirmation;