/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Row, Col } from "reactstrap";
import i18next from "i18next";
import Email from "../../../components/Forms/Inputs/email";
import Choice from "../../../components/Forms/Inputs/choice";
import Description from "../../../components/Forms/Inputs/description";
import DateTime from "../../../components/Forms/Inputs/dateTime";
import Label from "../../../components/Forms/Inputs/label";
import Captcha from "../../../components/Arkose/Captcha";
import * as paths from "../../../constants/paths";
import {
  helpCenterUrls,
  cause,
  convenience,
  addressChange,
  situationChange,
  waterGazBill,
  phoneBill,
  rentReceipt,
  bankOrCredit,
  homeInsurance,
  pacs,
  marriage,
  death,
  administrationRequest,
} from "../../../constants/label";
import { getPageTitle } from "../../../helpers/layout";
import {
  submitForm,
  setLoading,
  setRequestStatus,
} from "../../../store/actions/form";
import { getTime } from "../../../helpers/date";
import { addMessageListener, sendACKMessage } from "../../common";
import { isValidEmail } from "../../../helpers/input";
import MultipleFile from "components/Forms/Inputs/multiUpload";
import { uploadFiles } from "repositories/form";
import PreConfirmationDialog from "./preConfirmation";
import Dialog from "components/Dialogs/Dialog";
import DocumentSubmissionAddress from "./documentSubmissionAddress";

const { REACT_APP_FMP_ARKOSE_PUBLIC_KEY } = process.env;
const todayDate = getTime(new Date());
const defaultItem = {
  email: "",
  cancellationReason: convenience,
  terminationDate: todayDate,
  message: "",
  submissionDate: "",
};

const CustomForm = (props) => {
  const dispatch = useDispatch();
  const brand = useSelector((state) => state.form.brand);
  const folder = useSelector((state) => state.form.folder);
  const platform = useSelector((state) => state.form.platform);
  const requestStatus = useSelector((state) => state.form.requestStatus);
  const externalData = useSelector((state) => state.form.externalData);
  const language = useSelector((state) => state.form.local);
  let history = useHistory();
  const [editedItem, setEditedItem] = useState(defaultItem);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [preConfirmationItem, setPreConfirmationItem] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [token, setToken] = useState("");
  const reasonLookup = {
    [convenience]: i18next.t("Cancel For Convenience"),
    [cause]: i18next.t("Cancel For Cause"),
    [addressChange]: i18next.t("Address change"),
    [situationChange]: i18next.t("Change in family situation"),
  };
  const addressChangeDocLookup = {
    [waterGazBill]: i18next.t("Water, gas or electricity bill"),
    [phoneBill]: i18next.t("Phone bill less than 3 months old"),
    [rentReceipt]: i18next.t("Rent receipt of less than 3 months"),
    [bankOrCredit]: i18next.t(
      "Bank or credit account statement for the last 12 months"
    ),
    [homeInsurance]: i18next.t("Home insurance declaration"),
  };
  const situationChangeDocLookup = {
    [pacs]: i18next.t("PACS certificate"),
    [marriage]: i18next.t("Marriage certificate"),
    [death]: i18next.t("Death certificate or family record book"),
    [administrationRequest]: i18next.t(
      "Request to the tax administration following the birth of a child or the departure of a child from the home"
    ),
  };
  const [files, setFiles] = useState({});
  const [arkoseSource, setArkoseSource] = useState("");
  const [errors, setErrors] = useState({});
  const [uploadDone, setUploadDone] = useState(false);
  const needJustif = () =>
    [addressChange, situationChange].includes(editedItem.cancellationReason);
  const submit = "submit";

  useEffect(() => {

    addMessageListener();
    sendACKMessage();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (brand) document.title = getPageTitle(brand, i18next.t("Cancellation form"));
    // eslint-disable-next-line
  }, [brand]);

  useEffect(() => {
    if (uploadDone.response === true) {
      const item = getUpdatedItem(uploadDone.files);
      dispatch(submitForm({ data: item, token: token }));
    } else if (uploadDone.response === false) {
      dispatch(
        setRequestStatus({
          status: false,
          message: i18next.t("Something went wrong. Please try again later"),
        })
      );
      dispatch(setLoading(false));
      setShowCaptcha(false);
      setToken("");
    }
    // eslint-disable-next-line
  }, [uploadDone]);

  useEffect(async () => {
    if (token) {
      if (arkoseSource !== "" && arkoseSource !== submit) {
        setFiles({
          ...files,
          ...{
            [arkoseSource]: { ...files[arkoseSource], ...{ token: token } },
          },
        });
      } else {
        dispatch(setLoading(true));
        if (needJustif()) {
          setUploadDone(await uploadFiles(fileToUpload()));
        } else setUploadDone({ response: true });
      }
    }
    // eslint-disable-next-line
  }, [token]);

  const getUpdatedItem = (filesToUpload) => {
    const item = { ...editedItem };
    item["country"] = i18next.t("form_country");
    item["language"] = i18next.t("form_language");
    item["submissionDate"] = todayDate;
    item["brand"] = brand;
    item["platform"] = platform;
    if (item.cancellationReason === convenience) {
      delete item.message;
    }

    if (filesToUpload) {
      item["attachments"] = filesToUpload.map((f) => ({
        originalFileName: f.originalFileName,
        key: f.name,
        type: f.type,
        shortFileName: f.shortFileName,
      }));
    }
    if (externalData.id) item["userId"] = externalData.id;
    if (item.terminationDate)
      item.terminationDate = getTime(item.terminationDate);
    return item;
  };

  const fileToUpload = () => {
    let counts = {};
    const timestamp = getTime();
    return Object.values(files).map(file => {
      counts[file.type] = counts[file.type] ? counts[file.type] + 1 : 1;
      const shortFileName = `${file.type}${counts[file.type] === 1 ? "" : "-" + counts[file.type]}.${file.extension}`;
      const fileName = `${editedItem.email}/${timestamp}/${shortFileName}`;

      return {
        originalFileName: file.name,
        name: fileName,
        shortFileName: shortFileName,
        type: file.type,
        content: file.content.split(",")[1],
        token: file.token,
      };
    })
  }

  useEffect(() => {
    dispatch(setLoading(false));
    if (requestStatus.status) {
      history.push(
        props.location.pathname.replace(
          paths.type_form,
          paths.type_confirmation
        )
      );
    }
    // eslint-disable-next-line
  }, [requestStatus]);

  const onChange = async (field, value) => {
    let changedData = { ...editedItem };
    if (field === "cancellationReason") {
      setFiles({});
      if (value === convenience) {
        changedData["message"] = "";
        changedData["terminationDate"] = todayDate;
      }
    }

    changedData[field] = value;
    setEditedItem(changedData);
  };

  const onFileChange = (data) => {
    const newKey = Object.keys(data).find((key) => !data[key].token);
    if (newKey) {
      setArkoseSource(newKey);
    }
    setFiles(data);
  };

  useEffect(() => {
    if (arkoseSource) setShowCaptcha(true);
    // eslint-disable-next-line
  }, [arkoseSource]);

  const onSubmit = (event) => {
    const form = event.currentTarget;
    if (
      !form.checkValidity() ||
      !isValidEmail(editedItem.email) ||
      (needJustif() && Object.keys(errors).length > 0)
    ) {
      event.stopPropagation();
    } else {
      const updatedItem = getUpdatedItem(fileToUpload());
      setPreConfirmationItem(updatedItem);
      toggle();
    }
    event.preventDefault();
  };

  const onConfirmSubmit = (event) => {
    setArkoseSource(submit);
  };

  const onSuccess = (token) => {
    setToken(token);
    setShowCaptcha(false);
  };

  const p1 = i18next.t(
    "If you wish to manage your subscription or your Account in any other way, please visit our Help Centre for further information."
  );
  return (
    <div className="body">
      <div className="center-content">
        <h1 className="title max-font">{i18next.t("Terminate contracts")}</h1>
        <div className="paragraph-padding">
          <p className="paragraph">
            {i18next.t(
              "If you purchased your subscription from us, you can cancel it directly within your Account, or you can use this form."
            )}
            <br />
            <br />
            {i18next.t(
              "If you purchased your subscription through a third party (for example via an app store or one of our third party partners) and wish to cancel, you will need to do so through that third party."
            )}
            <br />
          </p>
          <p className="paragraph paragraph-bold">
            {p1.split("#linkToHelpCenter")[0]}
            <u>
              <a
                style={{ textDecoration: "revert" }}
                href={helpCenterUrls(folder)[brand]}
              >
                {i18next.t("help center")}
              </a>
            </u>
            {p1.split("#linkToHelpCenter")[1]}
          </p>
        </div>
        <Captcha
          show={showCaptcha}
          publickey={REACT_APP_FMP_ARKOSE_PUBLIC_KEY}
          language={language}
          onCompleted={(response) => onSuccess(response.token)}
          onError={(response) => alert(response)}
          onFailed={(response) => alert(response)}
        />
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md={12}>
              <Email
                field={"email"}
                title={i18next.t("Email address used to register your Account")}
                value={editedItem.email ? editedItem.email : externalData.email}
                isRequired={true}
                isReadOnly={false}
                handleChange={onChange}
              // placeholder={i18next.t("example")}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Choice
                field={"cancellationReason"}
                title={i18next.t("Reason For Cancelling")}
                value={editedItem.cancellationReason}
                placeholder={i18next.t("Please select")}
                lookup={reasonLookup}
                isRequired={false}
                isReadOnly={false}
                handleChange={onChange}
              />
            </Col>
          </Row>
          {needJustif() && (
            <>
              <Row>
                <Col md={12}>
                  <MultipleFile
                    field={"uploadFiles"}
                    item={editedItem}
                    files={files}
                    setFiles={onFileChange}
                    errors={errors}
                    setErrors={setErrors}
                    lookup={
                      editedItem.cancellationReason === addressChange
                        ? addressChangeDocLookup
                        : situationChangeDocLookup
                    }
                    minimum={
                      editedItem.cancellationReason === addressChange ? 2 : 1
                    }
                    maximum={5}
                  />
                </Col>
              </Row>
              <DocumentSubmissionAddress />
            </>
          )}
          {editedItem.cancellationReason === cause && (
            <>
              <Row>
                <Col md={12}>
                  <DateTime
                    field={"terminationDate"}
                    title={i18next.t("Date Of Termination")}
                    value={editedItem.terminationDate}
                    isRequired={false}
                    isReadOnly={false}
                    handleChange={onChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Description
                    field={"message"}
                    title={i18next.t("Message")}
                    value={editedItem.message}
                    isRequired={false}
                    maxLength={140}
                    isReadOnly={false}
                    handleChange={onChange}
                    placeholder={i18next.t(
                      "Please provide detail on your reason for cancelling. Do not share any sensitive information on this form like passwords or payment details. If you wish to manage your subscription or your Account in any other way, please visit our Help Centre for further information."
                    )}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col md={12}>
              <p className="paragraph paragraph-padding">
                {i18next.t(
                  "If you cancel for convenience, your subscription will end at the end of your current billing cycle."
                )}
                <br />
                <br />
                {i18next.t(
                  "We will email you a copy of your completed cancellation form. You may also wish to take a screenshot of this screen once you have completed it for your records"
                )}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button type="submit" color="primary" block>
                {i18next.t("Cancel Now")}
              </Button>
            </Col>
          </Row>
          {requestStatus.message && (
            <Row>
              <Col md={12}>
                <div className="error">
                  <Label field={"errorMessage"} title={requestStatus.message} />
                </div>
              </Col>
            </Row>
          )}
        </Form>
        {modal && (
          <Dialog
            style={{ backgroundColor: "#f5f5f5" }}
            component={
              <PreConfirmationDialog
                item={preConfirmationItem}
                onConfirm={onConfirmSubmit}
                onCancel={toggle}
              />
            }
            handleClose={toggle}
          />
        )}
      </div>
    </div>
  );
};

export default CustomForm;
