import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { ReduxProvider } from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/fmp.scss?v=1.2.0";
import App from "views"

const hist = createBrowserHistory();
ReactDOM.render(
  <ReduxProvider>
    <App hist={hist} />
  </ReduxProvider>,
  document.getElementById("root")
);
