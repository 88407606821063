import React from "react";
import { Button } from "reactstrap";
import i18next from "i18next";
import ConfirmationFields from "./confirmationFields";

const PreConfirmation = ({ item, onConfirm, onCancel }) => {
  return (
    <div>
      <Button
        close
        onClick={onCancel}
        style={{ position: "absolute", top: "10px", right: "10px" }}
      />
      <div className="center-content">
        <h1 className="title">
          {i18next.t(
            "You are about to submit your cancellation request to us."
          )}
        </h1>
        <p className="paragraph paragraph-padding">
          {i18next.t("Please verify the information you have provided to us")}
        </p>
        <ConfirmationFields item={item} />
      </div>
      <Button className="mt-4" type="button" block onClick={onCancel}>
        {i18next.t("Return to form")}
      </Button>{" "}
      <Button color="primary" block onClick={onConfirm}>
        {i18next.t("Submit the cancellation request")}
      </Button>
    </div>
  );
};

export default PreConfirmation;
