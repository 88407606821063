import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';
import RobotoRegular from 'assets/fonts/roboto/Roboto-Regular.ttf';
import RobotoBold from 'assets/fonts/roboto/Roboto-Bold.ttf';

// Create styles
const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        flexDirection: 'row',
        // backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    title: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: '16',
        textAlign: 'center',
    },
    subTitle: {
        fontSize: 12,
        textAlign: 'center',
        paddingTop: 10,
    },
    viewData: {
        paddingTop: 50,
    },
    label: {
        margin: 12,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        width: 'auto',
        fontSize: 12,
        textAlign: 'left',
        paddingBottom: 10,
        flexShrink: 0,
        flexWrap: 'nowrap',  // Prevent wrapping
        overflow: 'hidden',  // Ensure no overflow       
        minWidth: 0,
    },
    text: {
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        margin: 12,
        fontSize: 12,
        textAlign: 'left',
        paddingBottom: 10,
        flexShrink: 0,
        flexWrap: 'nowrap',  // Prevent wrapping
        overflow: 'hidden',  // Ensure no overflow
        width: 'auto',
        minWidth: 0,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
});

Font.register({
    family: 'Roboto',
    fonts: [
        { src: RobotoRegular, fontWeight: 'normal' },
        { src: RobotoBold, fontWeight: 'bold' },
    ]
});

// Create Document Component
const PdfDocument = (props) => {
    const { data } = props;

    let viewkey = 1;
    let textkey = 1;
    let labelkey = 1;

    return (
        <Document>
            <Page size="A4" style={styles.body}>
                <View style={styles.section}>
                    {data.logo && (
                        <Image
                            style={{
                                height: data.logo.height,
                                width: data.logo.width,
                                marginBottom: 50,
                            }}
                            src={data.logo.path}
                        />
                    )}
                    {(data.title && <Text key={"title-" + data.title} style={styles.title} fixed>{data.title}</Text>)}
                    {(data.subTitle && <Text key={"subTitle-" + data.subTitle} style={styles.subTitle} fixed>{data.subTitle}</Text>)}
                    <View key={"view-data-" + (viewkey)} style={styles.viewData}>
                        {(data.rows && data.rows.length > 0 ?
                            (data.rows.map((row) => {
                                const properties = Object.keys(row);

                                return (<View key={"view-" + (viewkey++)} style={styles.row}>
                                    <Text key={"label-" + (labelkey++)} style={styles.label} >{properties[0]}</Text>
                                    <Text key={"text-" + (textkey++)} style={styles.text} >{row[properties[0]]} </Text>
                                </View>)
                            })) : <></>)}
                    </View>
                </View>
            </Page>
        </Document>);
}


export default PdfDocument;