import Description from "components/Forms/Inputs/description";
import Text from "components/Forms/Inputs/text";
import { cause } from "constants/label";
import { getDateTime, getDate } from "../../../helpers/date";
import i18next from "i18next";
import React from "react";
import { Col, Label, Row } from "reactstrap";
import { AttachFile as AttachFileIcon } from "../../../components/Forms/Icons/icons";

const ConfirmationFields = ({ item }) => {
  return (
    <>
      <Row>
        <Col md={12}>
          <Text
            field={"email"}
            title={i18next.t("Email address used to register your Account")}
            value={item.email}
            isRequired={false}
            isReadOnly={true}
            placeholder={i18next.t("example")}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Text
            field={"cancellationReason"}
            title={i18next.t("Reason For Cancelling")}
            value={i18next.t(item.cancellationReason)}
            isRequired={false}
            isReadOnly={true}
          />
        </Col>
      </Row>
      {item.cancellationReason === cause && (
        <>
          <Row>
            <Col md={12}>
              <Text
                field={"terminationDate"}
                title={i18next.t("Date Of Termination")}
                value={
                  item.terminationDate
                    ? getDate(item.terminationDate, "de-DE")
                    : item.terminationDate
                }
                isRequired={false}
                isReadOnly={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Description
                field={"message"}
                title={i18next.t("Message")}
                value={item.message}
                isRequired={false}
                isReadOnly={true}
              // placeholder={i18next.t("Please provide detail on your reason for cancelling. Do not share any sensitive information on this form like passwords or payment details. If you wish to manage your subscription or your Account in any other way, please visit our Help Centre for further information.")}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col md={12}>
          <Text
            field={"submissionDate"}
            title={i18next.t("Date of Submission")}
            value={
              item.submissionDate
                ? getDateTime(item.submissionDate, "de-DE")
                : item.submissionDate
            }
            isRequired={false}
            isReadOnly={true}
          />
        </Col>
      </Row>
      {item.attachments && item.attachments.length > 0 && (
        <>
          <Label>{i18next.t("Justificative documents")}</Label>
          {item.attachments.map((file) => (
            <div style={{ paddingLeft: "10px", paddingBottom:"10px" }}>
              <AttachFileIcon />
              <span
                style={{
                  paddingLeft: "2px",
                  paddingRight: "30px",
                  color: "#787878",
                  fontStyle: "italic",
                }}
              >
                {file.shortFileName}
              </span>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default ConfirmationFields;
