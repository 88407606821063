import { trustedUrls, waitingForFccaAutofill } from "../../constants/form";
import { setPrefillData } from "../../store/actions/form";
import { store, dispatch } from '../../store';
import { stringContains } from '../../helpers/filters';

export const addMessageListener = () => {
    window.onmessage = (event) => {
        if ((trustedUrls.filter((url) => stringContains(event.origin, url)).length > 0) && event.data.email) {
            dispatch(setPrefillData(event.data));
            console.log("Data received from " + event.origin);
            console.log("Data: " + JSON.stringify(event.data));
        }

        return;
    };
}

export const sendACKMessage = () => {
    if (!store.getState().form.externalData.email && window.opener)
        window.opener.postMessage(waitingForFccaAutofill, '*')
}