import React from "react";

export const getBreadCrumbFromUrl = (url) => {
  const splittedUrl = url.split("/");
  if (splittedUrl.length > 4) {
    splittedUrl.splice(0, 4);
    return splittedUrl.join(" > ");
  }
  return "";
};

export const getBreadCrumbFromArray = (array, prevText = "") => {
  if (prevText === "") return array.join(" > ");
  return prevText + " > " + array.join(" > ");
};

export const getBreadCrumbFromArrayWithActions = (array) => {
  let counter = array.length - 1;
  const res = array.map((item) => (
    <>
      <React.Fragment>{item}</React.Fragment>
      <React.Fragment>{counter-- > 0 && " > "}</React.Fragment>
    </>
  ));
  return res;
};

export const generateHeader = (title, action) => (
  <div
    className="d-inline"
    style={{
      cursor: "pointer",
      textDecoration: "underline",
    }}
    onClick={action}
  >
    {title}
  </div>
);

export const getFavicon = () => {
  return document.getElementById("favicon");
};

export const getPlatform = (url) => {
  return url.split("/")[5];
};

export const getPageTitle = (brand, page) => {
  return brand + " - " + page;
};
