import React from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import { DeleteIcon, HelpIcon } from "../Icons/icons";

export const ascOrder = "ascOrder";
export const descOrder = "descOrder";
export default function Choice(props) {
  const {
    field,
    title,
    value,
    isRequired,
    lookup,
    defaultValue,
    isReadOnly,
    placeholder,
    handleChange,
    handleDelete,
    faq,
    sortBy,
  } = props;

  const renderChoiceOption = (key, text) => {
    return (
      <option key={key} value={key}>
        {text}
      </option>
    );
  };

  return (
    <FormGroup key={"form" + field} controlid={field}>
      {title && <Label>{isRequired ? title + "*" : title}</Label>}
      {faq && <HelpIcon
        title={'FAQ'}
        onClick={() =>
          window.open(faq)
        }
        style={{ cursor: 'pointer' }}
      />}
      <Row>
        <Col md={handleDelete ? 10 : 12}>
          <Input
            key={field}
            required={isRequired}
            placeholder={placeholder}
            type="select"
            value={value ? value : ""}
            onChange={(e) => handleChange(field, e.target.value)}
            disabled={isReadOnly}
          >
            <option key={0} value="" disabled defaultValue hidden>
              {placeholder ? placeholder : ""}
            </option>
            {defaultValue && (
              <option key={defaultValue.key}>{defaultValue.value}</option>
            )}
            {Object.keys(lookup)
              .sort(function (a, b) {
                return sortBy === ascOrder
                  ? a - b
                  : sortBy === descOrder
                    ? b - a
                    : a; //no sort
              })
              .map((key) => renderChoiceOption(key, lookup[key]))}
          </Input>
        </Col>
        {handleDelete &&
          <Col md={2}>
            <DeleteIcon onClick={(e) => {
              handleDelete(field);
            }} />
          </Col>}
      </Row>
    </FormGroup>
  );
}
