import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../header";
// import Footer from "./footer.js";
import { Route, Switch } from "react-router-dom";
import { setBrand, setPlatform } from "../../../store/actions/form";
import { getFavicon, getPlatform } from "../../../helpers/layout";
import { eurosportRoutes } from "../../../routes";
import * as paths from "../../../constants/paths";
import { Eurosport } from "../../../constants/label";

const Main = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const favicon = getFavicon();
        favicon.href = "/faviconEurosport.ico";

        dispatch(setBrand(Eurosport));
        dispatch(setPlatform(getPlatform(window.location.href)));
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <Header />
            <Switch>
                {eurosportRoutes(paths.subDomainGermany).map((route, key) =>
                    <Route
                        path={route.paths}
                        component={route.component}
                        key={key}
                    />)}
                {/* <Redirect to={paths.eurosport_website_form(paths.layout)} /> */}
            </Switch>
            {/* <Footer /> */}
        </div>
    );
};

export default Main;
