import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Row, Col } from "reactstrap";
import i18next from "i18next";
import Email from "../../../components/Forms/Inputs/email";
import Choice from "../../../components/Forms/Inputs/choice";
import Description from "../../../components/Forms/Inputs/description";
import Label from "../../../components/Forms/Inputs/label";
import Captcha from "../../../components/Arkose/Captcha";
import * as paths from "../../../constants/paths";
import { helpCenterUrls } from "../../../constants/label";
import { getPageTitle } from "../../../helpers/layout";
import { submitForm, setLoading } from "../../../store/actions/form";
import { getTime } from "../../../helpers/date";
import { addMessageListener, sendACKMessage } from "../../common";
import { isValidEmail } from "../../../helpers/input";

const { REACT_APP_FMP_ARKOSE_PUBLIC_KEY } = process.env;
const todayDate = getTime(new Date());
const defaultItem = {
  email: "",
  message: "",
  submissionDate: "",
  quebecResidence: "yes",
};

const CustomForm = (props) => {
  const dispatch = useDispatch();
  const brand = useSelector((state) => state.form.brand);
  const folder = useSelector((state) => state.form.folder);
  const requestStatus = useSelector((state) => state.form.requestStatus);
  const externalData = useSelector((state) => state.form.externalData);
  const language = useSelector((state) => state.form.local);
  let history = useHistory();
  const [editedItem, setEditedItem] = useState(defaultItem);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [token, setToken] = useState("");
  const toggleCaptcha = () => setShowCaptcha(!showCaptcha);
  // const reasonLookup = { [convenience]: i18next.t("Cancel For Convenience"), [cause]: i18next.t("Cancel For Cause") };
  const QuebecResidentLookup = { yes: i18next.t("Yes"), no: i18next.t("No") };

  useEffect(() => {
    addMessageListener();
    sendACKMessage();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (brand) document.title = getPageTitle(brand, i18next.t("Cancellation form"));
    // eslint-disable-next-line
  }, [brand]);

  useEffect(() => {
    if (token) {
      toggleCaptcha();
      const item = { ...editedItem };
      item["country"] = i18next.t("form_country");
      item["language"] = i18next.t("form_language");
      item["submissionDate"] = todayDate;
      item["brand"] = brand;
      dispatch(setLoading(true));
      dispatch(submitForm({ data: item, token: token }));
    }
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    dispatch(setLoading(false));
    if (requestStatus.status) {
      history.push(
        props.location.pathname.replace(
          paths.type_form,
          paths.type_confirmation
        )
      );
    }
    // eslint-disable-next-line
  }, [requestStatus]);

  const onChange = async (field, value) => {
    let changedData = { ...editedItem };
    changedData[field] = value;
    setEditedItem(changedData);
  };

  const onSubmit = (event) => {
    const form = event.currentTarget;
    if (!form.checkValidity() || !isValidEmail(editedItem.email)) {
      event.stopPropagation();
    } else {
      toggleCaptcha();
    }
    event.preventDefault();
  };

  const onSuccess = (key) => {
    setToken(key);
  };
  const p1 = i18next.t(
    "If you wish to manage your subscription or your Account in any other way, please visit our Help Centre for further information."
  );
  return (
    <div className="body">
      <div className="center-content">
        <h1 className="title">{i18next.t("Terminate contracts")}</h1>
        <div className="paragraph-padding">
          <p className="paragraph">
            {i18next.t(
              "If you purchased your subscription from us, you can cancel it directly within your Account, or you can use this form."
            )}
            <br />
            <br />
            {i18next.t(
              "If you purchased your subscription through a third party (for example via an app store or one of our third party partners) and wish to cancel, you will need to do so through that third party."
            )}
            <br />
          </p>
          <p className="paragraph paragraph-bold">
            {p1.split("#linkToHelpCenter")[0]}
            <u>
              <a
                style={{ textDecoration: "revert" }}
                href={helpCenterUrls(folder)[brand]}
              >
                {i18next.t("help center")}
              </a>
            </u>
            {p1.split("#linkToHelpCenter")[1]}
          </p>
        </div>
        <Captcha
          show={showCaptcha}
          publickey={REACT_APP_FMP_ARKOSE_PUBLIC_KEY}
          language={language}
          onCompleted={(response) => onSuccess(response.token)}
          onError={(response) => alert(response)}
          onFailed={(response) => alert(response)}
        />
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md={12}>
              <Email
                field={"email"}
                title={i18next.t("Email address used to register your Account")}
                value={editedItem.email ? editedItem.email : externalData.email}
                isRequired={true}
                isReadOnly={false}
                handleChange={onChange}
              // placeholder={i18next.t("example")}
              />
            </Col>
          </Row>
          {/* <Row>
                        <Col md={12}>
                            <Choice
                                field={"cancellationReason"}
                                title={i18next.t("Reason For Cancelling")}
                                value={editedItem.cancellationReason}
                                placeholder={i18next.t("Please select")}
                                lookup={reasonLookup}
                                isRequired={false}
                                isReadOnly={false}
                                handleChange={onChange}
                            />
                        </Col>
                    </Row> */}
          <Row>
            <Col md={12}>
              <Choice
                field={"quebecResidence"}
                title={i18next.t("Do you reside in Quebec?")}
                value={editedItem.quebecResidence}
                placeholder={i18next.t("Please select")}
                lookup={QuebecResidentLookup}
                isRequired={false}
                isReadOnly={false}
                handleChange={onChange}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Description
                field={"message"}
                title={i18next.t("Message")}
                value={editedItem.message}
                isRequired={false}
                maxLength={140}
                isReadOnly={false}
                handleChange={onChange}
                placeholder={i18next.t(
                  "Please provide detail on your reason for cancelling. Do not share any sensitive information on this form like passwords or payment details. If you wish to manage your subscription or your Account in any other way, please visit our Help Centre for further information."
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p className="paragraph paragraph-padding">
                {i18next.t(
                  "If you cancel for convenience, your subscription will end at the end of your current billing cycle."
                )}
                <br />
                <br />
                {i18next.t(
                  "We will email you a copy of your completed cancellation form. You may also wish to take a screenshot of this screen once you have completed it for your records"
                )}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button type="submit" color="primary" block>
                {i18next.t("Cancel Now")}
              </Button>
            </Col>
          </Row>
          {requestStatus.message && (
            <Row>
              <Col md={12}>
                <div className="error">
                  <Label field={"errorMessage"} title={requestStatus.message} />
                </div>
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </div>
  );
};

export default CustomForm;
