import React from "react";
import { FormGroup, Label } from "reactstrap";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import { formatDate } from "../../../helpers/date";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-input": {
        paddingTop: "0.6rem",
        paddingRight: "0.89rem",
        paddingBottom: "0.6rem",
        paddingLeft: "0.89rem",

      }
    }
  }
});

export default function DateTime(props) {
  const { field, title, isRequired, value, isReadOnly, handleChange } = props;
  const classes = useStyles();

  return (
    <FormGroup key={"form" + field} controlid={"date-picker-inline" + field}>
      {title && <Label>{isRequired ? title + "*" : title}</Label>}
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            // disableToolbar
            //variant="inline"
            disabled={isReadOnly}
            className={classes.root}
            inputVariant="outlined"
            format="dd.MM.yyyy"
            id={field}
            value={value ? formatDate(value) : formatDate()}
            onChange={(e) => handleChange(field, e)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            InputProps={{
              style: {
                fontSize: "1rem",
                width: "100%",
                height: "100%",
              }
            }}
            style={{
              fontSize: "1rem",
              height: "100%",
              width: "100%",
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    </FormGroup>
  );
}
