import {
  SET_BRAND, SET_REQUEST_STATUS, SET_PLATFORM, SET_FORM, SET_PREFILL_DATA, SET_LANGUAGE, SET_LOADING, SET_FOLDER
} from "../../constants/actions-types";

const initialState = {
  local: "de",
  brand: "",
  platform: "",
  folder: "",
  data: {},
  externalData: {},
  requestStatus: { status: false, message: "" },
  isLoading: false
};

const handlers = {
  [SET_BRAND](state, payload) {
    return {
      ...state,
      brand: payload,
    };
  },
  [SET_PLATFORM](state, payload) {
    return {
      ...state,
      platform: payload,
    };
  },
  [SET_REQUEST_STATUS](state, payload) {
    return {
      ...state,
      requestStatus: payload,
    };
  },
  [SET_FORM](state, payload) {
    return {
      ...state,
      data: payload,
    };
  },
  [SET_PREFILL_DATA](state, payload) {
    return {
      ...state,
      externalData: payload,
    };
  },
  [SET_LANGUAGE](state, payload) {
    return {
      ...state,
      local: payload,
    };
  },
  [SET_LOADING](state, payload) {
    return {
      ...state,
      isLoading: payload,
    };
  },
  [SET_FOLDER](state, payload) {
    return {
      ...state,
      folder: payload,
    };
  },

};

const FormReducer = (state = initialState, { type, payload }) => {
  const actionHandler = handlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
};

export default FormReducer;
