import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../header";
import Footer from "../footer.js";
import { Route, Switch } from "react-router-dom";
import { getFavicon, getPlatform } from "../../../helpers/layout";
import { dplusRoutes } from "../../../routes";
import * as paths from "../../../constants/paths";
import { DiscoveryPlus } from "../../../constants/label";
import { setBrand, setPlatform } from "../../../store/actions/form";

const Main = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const favicon = getFavicon();
        favicon.href = "/faviconDplus.ico";

        dispatch(setBrand(DiscoveryPlus));
        dispatch(setPlatform(getPlatform(window.location.href)));
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <Header />
            <Switch>
                {dplusRoutes(paths.subDomainCanada).map((route, key) =>
                    <Route
                        path={route.paths}
                        component={route.component}
                        key={key}
                    />)}
            </Switch>
            <Footer />
        </div>
    );
};

export default Main;
