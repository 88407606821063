import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

export default function Text(props) {
  const {
    field,
    title,
    isRequired,
    value,
    isReadOnly,
    placeholder,
    handleChange,
    style,
  } = props;
  return (
    <FormGroup key={"form" + field} controlid={field}>
      {title && <Label>{isRequired ? title + "*" : title}</Label>}
      <Input
        style={style}
        key={field}
        type="text"
        required={isRequired}
        value={value ? value : ""}
        onChange={(e) => handleChange(field, e.target.value)}
        disabled={isReadOnly}
        placeholder={placeholder}
      />
    </FormGroup>
  );
}
