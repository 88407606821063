import React, { useEffect } from "react";
import ArkoseLabs from "./ArkoseLabs";

const Captcha = (props) => {
    // State to hold the token or error response from the Arkose Labs API
    const { show, publickey, language, onReady, onShow, onShown, onSuppress, onCompleted, onHide, onReset, onError, onFailed } = props;
    const captchaButton = React.createRef();
    const idSelector = 'triggerbutton'

    useEffect(() => {
        if (show)
            captchaButton.current.click();
        // eslint-disable-next-line
    }, [show]);

    // Create the setup function that the Arkose Labs API will use to configure it's use
    // and the callbacks that it will trigger
    function setupEnforcement(enforcementObject) {
        const config = {
            selector: '#' + idSelector,
            language: language ? language : 'en',
            mode: 'modal',
        }

        config["data"] = { id: 'customer_request' }

        if (onReady)
            config["onReady"] = onReady
        if (onShow)
            config["onShow"] = onShow
        if (onShown)
            config["onShown"] = onShown
        if (onSuppress)
            config["onSuppress"] = onSuppress
        if (onCompleted)
            config["onCompleted"] = onCompleted
        if (onHide)
            config["onHide"] = onHide
        if (onReset)
            config["onReset"] = onReset
        if (onError)
            config["onError"] = onError
        if (onFailed)
            config["onFailed"] = onFailed

        enforcementObject.setConfig(config);
    }

    // Make the setup function a global variable so that the Arkose Labs API can run it
    // once the API has loaded. The name of this variable MUST match the name of the setup
    // function defined in the ArkoseLabs component 'data-callback' attribute
    window.setupEnforcement = setupEnforcement;

    return (
        <div className='row justify-content-center'>
            <ArkoseLabs callbackFuncName="setupEnforcement" idSelector={idSelector} publicKey={publickey} />
            <button
                ref={captchaButton}
                id={idSelector}
                hidden={true}
            />
        </div>
    );
};

export default Captcha;