export const EUROSPORT_PLAYER_FORM = "eurosportPlayer_form";
export const EUROSPORT_PLAYER_CONFIRMATION = "eurosportPlayer_confirmation";
export const EUROSPORT_PLAYER_IOSAPP_INTERSTITIAL = "eurosportPlayer_iosapp_interstitial";
export const EUROSPORT_PLAYER_ANDROIDAPP_INTERSTITIAL = "eurosportPlayer_androidapp_interstitial";
export const EUROSPORT_PLAYER_CONNECTEDTV_INTERSTITIAL = "eurosportPlayer_connectedtv_interstitial";

export const DPLUS_FORM = "dplus_form";
export const DPLUS_CONFIRMATION = "dplus_confirmation";
export const DPLUS_IOSAPP_INTERSTITIAL = "dplus_iosapp_interstitial";
export const DPLUS_ANDROIDAPP_INTERSTITIAL = "dplus_androidapp_interstitial";
export const DPLUS_CONNECTEDTV_INTERSTITIAL = "dplus_connectedtv_interstitial";

export const GOLFTV_FORM = "golf_tv_form";
export const GOLFTV_CONFIRMATION = "golf_tv_confirmation";
export const GOLFTV_IOSAPP_INTERSTITIAL = "golf_tv_iosapp_interstitial";
export const GOLFTV_ANDROIDAPP_INTERSTITIAL = "golf_tv_androidapp_interstitial";
export const GOLFTV_CONNECTEDTV_INTERSTITIAL = "golf_tv_connectedtv_interstitial";

export const EUROSPORT_FORM = "eurosport_form";
export const EUROSPORT_CONFIRMATION = "eurosport_confirmation";
export const EUROSPORT_IOSAPP_INTERSTITIAL = "eurosport_iosapp_interstitial";
export const EUROSPORT_ANDROIDAPP_INTERSTITIAL = "eurosport_androidapp_interstitial";
export const EUROSPORT_CONNECTEDTV_INTERSTITIAL = "eurosport_connectedtv_interstitial";

export const MAX_FORM = "max_form";
export const MAX_CONFIRMATION = "max_confirmation";
export const MAX_IOSAPP_INTERSTITIAL = "max_iosapp_interstitial";
export const MAX_ANDROIDAPP_INTERSTITIAL = "max_androidapp_interstitial";
export const MAX_CONNECTEDTV_INTERSTITIAL = "max_connectedtv_interstitial";

export const GCN_FORM = "gcn_form";
export const GCN_CONFIRMATION = "gcn_confirmation";
export const GCN_IOSAPP_INTERSTITIAL = "gcn_iosapp_interstitial";
export const GCN_ANDROIDAPP_INTERSTITIAL = "gcn_androidapp_interstitial";
export const GCN_CONNECTEDTV_INTERSTITIAL = "gcn_connectedtv_interstitial";

