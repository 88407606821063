import { SUBMIT_FORM } from "../../constants/actions-types";
import { switchMap, flatMap, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { ofType } from "redux-observable";
import { setForm, setRequestStatus, setLoading } from "../../actions/form";
import { submitForm } from "../../../repositories/form";
import i18next from "i18next";

const submit = (action$) =>
  action$.pipe(
    ofType(SUBMIT_FORM),
    switchMap(({ payload }) =>
      from(submitForm(payload)).pipe(
        flatMap((resp) =>
          of(
            setForm(resp),
            setRequestStatus({ status: true, message: "" }),
            setLoading(false)
          )
        ),
        catchError((error) =>
          of(
            setRequestStatus({ status: false, message: i18next.t("Something went wrong. Please try again later") }),
            setLoading(false)
          )
        )
      )
    )
  );

export default submit;