export const getDateTime = (date, region) => {
  var d = new Date(date);
  return region ? d.toLocaleString(region) : d.toLocaleString();//'M/DD/YYYY, H:MM:SS AM/PM'
};

export const getDate = (date, region) => {
  let d = date ? new Date(date) : new Date();
  return region ? d.toLocaleDateString(region) : d.toLocaleDateString();//'M/DD/YYYY, H:MM:SS AM/PM'
};

export const getTime = (date) => {
  let d = date ? new Date(date) : new Date();
  return d.getTime(); //millisecondes
};

export const dateToSecondes = (date) => {
  var d = new Date(date);
  return d.getTime() / 1000; //secondes
};

export const formatDate = (date) => {
  let d = date ? new Date(date) : new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("/");
};

export const endOfYear = (date) => {
  let d = date ? new Date(date) : new Date(),
    year = d.getFullYear();
  return [12, 31, year].join("/") + " 23:59:59";
};

export const currentYear = (date) => {
  let d = date ? new Date(date) : new Date();
  return d.getFullYear();
};