import React from "react";
import { currentYear } from "../../helpers/date";
// used for making the prop types of this component
import PropTypes from "prop-types";
import i18next from "i18next";

const Footer = () => {
    const sections = [
        {
            key: "eurosportplayer",
            name: i18next.t("EUROSPORT PLAYER"),
            items: [
                {
                    key: "home",
                    title: i18next.t("Home"),
                    url: "https://www.eurosportplayer.com/home"
                },
                {
                    key: "schedule",
                    title: i18next.t("Schedule"),
                    url: "https://www.eurosportplayer.com/schedule"
                },
                {
                    key: "onDemand",
                    title: i18next.t("On demand"),
                    url: "https://www.eurosportplayer.com/on-demand"
                }
            ],
        },
        {
            key: "eurosport",
            name: i18next.t("EUROSPORT"),
            items: [{
                key: "eurosportSite",
                title: "Eurosport.de",
                url: "https://www.eurosport.de/"
            }]
        },
        {
            key: "legal",
            name: i18next.t("LEGAL"),
            items: [
                {
                    key: "legalInformation",
                    title: i18next.t("Legal information"),
                    url: "https://www.eurosport.de/eurosport/legal/2020-2021/rechtliche-informationen_sto7710118/story.shtml"
                },
                {
                    key: "termsOfUse",
                    title: i18next.t("Terms of use"),
                    url: "https://www.eurosport.de/eurosport/legal/2020-2021/ab-dem-1.-april-2020-gultige-nutzungsbedingungen_sto7711069/story.shtml"
                },
                {
                    key: "privacyPolicy",
                    title: i18next.t("Privacy policy"),
                    url: "https://www.eurosportplayer.com/privacy-policy"
                },
                {
                    key: "cookiesPolicy",
                    title: i18next.t("Cookies policy"),
                    url: "https://www.eurosportplayer.com/cookie-policy"
                },
                {
                    key: "eurosportPassInformation",
                    title: i18next.t("Eurosport Pass Information"),
                    url: "https://www.eurosportplayer.com/eurosportplayer-pass-information"
                },
                {
                    key: "modernSlaveryStatement",
                    title: i18next.t("Modern Slavery Statement"),
                    url: "https://corporate.discovery.com/discoverys-modern-slavery-statement/"
                },
            ],
        }
    ];

    return (
        <div className="eurosportPlayer-footer">
            <div className="footerLinks">
                <section className="styles-mainContainer">
                    <ul className="styles-footerNav">
                        {sections.map(section => {
                            return <li key={section.key} className="styles-container">
                                <p className="styles-header" data-sonic-attribute="footer-title">
                                    {section.name}
                                </p>
                                <div>
                                    <ul className="styles-list">
                                        {section.items.map(item => {
                                            return <li key={item.key}>
                                                <p className="styles-subheader">
                                                    <a className="styles-links" href={item.url}>
                                                        {item.title}
                                                    </a>
                                                </p>
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </li>
                        })}
                        <li key={"socialmedia"} className="styles-container">
                            <p className="styles-header" data-sonic-attribute="footer-title">
                                {i18next.t("FOLLOW US ON")}
                            </p>
                            <div>
                                <ul className="style-socialList">
                                    <li key={"Twitter"}>
                                        <a href="https://twitter.com/Eurosport_DE" className="styles-socialLink" rel="noopener noreferrer" target="_blank">
                                            <img className="styles-socialIcon" src="https://eu3-prod-images.disco-api.com/2019/11/19/b3ede9f0-198b-46d7-bb59-c5a090148919.png?w=40" alt="Twitter" />
                                        </a>
                                    </li>
                                    <li key={"Facebook"}>
                                        <a href="https://www.facebook.com/EurosportDE/" className="styles-socialLink" rel="noopener noreferrer" target="_blank">
                                            <img className="styles-socialIcon" src="https://eu3-prod-images.disco-api.com/2019/11/19/8979950f-9460-4171-aeed-5402e80ac25c.png?w=40" alt="Facebook" />
                                        </a>
                                    </li>
                                    <li key={"Instagram"}>
                                        <a href="https://www.instagram.com/eurosportde/" className="styles-socialLink" rel="noopener noreferrer" target="_blank">
                                            <img className="styles-socialIcon" src="https://eu3-prod-images.disco-api.com/2019/11/19/531c4fed-275b-4d44-b6b2-14a74579856d.png?w=40" alt="Instagram" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </section>
                <div className="styles-copyright">
                    <span>{"© " + currentYear() + " " + i18next.t("Eurosport ou ses affiliés. Tous droits réservés.")}</span>
                </div>
            </div>
        </div>);
}

Footer.propTypes = {
    default: PropTypes.bool,
    fluid: PropTypes.bool,
};

export default Footer;