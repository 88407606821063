
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FormGroup, Row, Col } from "reactstrap";
import i18next from "i18next";
import FileBrowser from "./upload";
import { imageToBase64 } from "helpers/file";
import { fileTypeCheck } from "helpers/file";
import { v4 } from 'uuid';
import Choice from "./choice";
import { Delete as DeleteIcon, AttachFile as AttachFileIcon, AddOutlined } from "../Icons/icons";

const MultipleFile = (props) => {
  const { field, files, setFiles, errors, setErrors, lookup, maximum, minimum = 1, } = props;
  const [fileInputs, setFileInputs] = useState({});
  const [showAdd, setShowAdd] = useState(false);

  const defaultBrowser = (count) => {
    const res = {};
    for (let i = 0; i < count; i++)
      res[v4()] = {
        name: "",
        type: "",
        extension: "",
        content: "",
      };
    return res;
  };

  useEffect(() => {
    setFileInputs(
      Object.keys(files).length === 0 ? defaultBrowser(minimum) : files
    );
  }, [files]);

  useEffect(() => {
    setShowAdd(
      Object.keys(fileInputs).length === Object.keys(files).length &&
      (!maximum || Object.keys(fileInputs).length < maximum)
    );
  }, [fileInputs]);

  const handleChange = async (field, key, value) => {
    let changedData = { ...fileInputs };
    if (field === "upload" && value) {
      if (value.size > 10485760) {
        changedData[key].name = "";
        changedData[key].extension = "";
        changedData[key].content = "";
        setFiles(changedData);
        setErrors({
          ...errors,
          ...{
            [key]: i18next.t("File length greater than maximum allowed 10 Mo"),
          },
        });
      } else if (
        !fileTypeCheck(value, [
          "application/pdf",
          "image/png",
          "application/msword",
          "image/jpeg",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ])
      ) {
        setErrors({
          ...errors,
          ...{
            [key]: i18next.t(
              "Please provide a document such as .jpeg, .pdf or .docx"
            ),
          },
        });
      } else {
        const base64 = await imageToBase64(value);
        const splits = value.name.split(".");
        changedData[key].name = value.name;
        changedData[key].extension = splits[splits.length - 1];
        changedData[key].content = base64;

        const editErrors = { ...errors };
        delete editErrors[key];
        setErrors(editErrors);
        setFiles(changedData);
      }
    } else if (field === "type") {
      changedData[key].type = value;
      changedData[key].name = "";
      changedData[key].extension = "";
      changedData[key].content = "";
      setFiles(changedData);
      setFileInputs(changedData);
    }
  };

  const handleDelete = (key) => {
    const inputs = { ...files };
    const editErrors = { ...errors };
    delete inputs[key];
    delete editErrors[key];
    setErrors(editErrors);
    setFiles(inputs);
  };

  const handleDeleteFile = (key) => {
    let changedData = { ...fileInputs };
    changedData[key].name = "";
    changedData[key].extension = "";
    changedData[key].content = "";
    setFileInputs(changedData);
  };

  const addFileInput = () => {
    setFileInputs({
      ...fileInputs,
      ...defaultBrowser(1),
    });
  };

  return (
    <FormGroup key={field} controlid={field}>
      <div style={{ paddingTop: "20px" }}>
        {Object.keys(fileInputs).map((key, index) => {
          return <>
            <Row>
              <Col md="12">
                <Choice
                  field={"type" + key}
                  title={`${i18next.t("Justificative documents")} ${index + 1
                    }`}
                  faq={
                    "https://help.eurosport.com/fr-fr/Answer/Detail/000004223"
                  }
                  value={fileInputs[key].type}
                  placeholder={i18next.t("Select your document")}
                  lookup={lookup}
                  isRequired={index + 1 <= minimum}
                  isReadOnly={false}
                  handleChange={(field, value) =>
                    handleChange("type", key, value)
                  }
                  handleDelete={
                    index >= minimum ? () => handleDelete(key) : false
                  }
                />
              </Col>
              {/* {Object.keys(files).length > minimum &&
                <Col md={2}>
                  <DeleteIcon onClick={Object.keys(files).length > minimum ? () => handleDelete(key) : false} />
                </Col>} */}
            </Row>
            {fileInputs[key].type && (
              <Row style={{ paddingBottom: "20px" }}>
                <Col md="12">
                  {!fileInputs[key].name && (
                    <FileBrowser
                      field={"upload" + key}
                      isRequired={index + 1 <= minimum}
                      value={fileInputs[key].name}
                      placeholder={i18next.t("Add your document")}
                      handleChange={(field, value) =>
                        handleChange("upload", key, value)
                      }
                      // handleDelete={Object.keys(files).length > minimum ? () => handleDelete(key) : false}
                      error={errors[key]}
                    />
                  )}
                  {fileInputs[key].name && (
                    <div style={{ paddingLeft: "10px" }}>
                      <AttachFileIcon />
                      <span
                        style={{
                          paddingLeft: "2px",
                          paddingRight: "30px",
                          color: "#787878",
                          fontStyle: "italic",
                        }}
                      >
                        {fileInputs[key].name}
                      </span>
                      <DeleteIcon
                        onClick={(e) => {
                          handleDeleteFile(key);
                        }}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </>
        })}
      </div>
      {
        showAdd &&
        <Row>
          <Col md="12">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

            <a href="#" onClick={addFileInput}>
              <AddOutlined />
              {i18next.t("Add a new document")}
            </a>
          </Col>
        </Row>
      }
    </FormGroup>
  );
};

export default MultipleFile;
