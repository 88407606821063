import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./locales/translation_en.json";
import frTranslations from "./locales/translation_fr.json";
import deTranslations from "./locales/translation_de.json";
import enCanadaTranslations from "./locales/translation_en_canada.json";
import frCanadaTranslations from "./locales/translation_fr_canada.json";
import { enGB, de, frFR, frCanada, enCanada } from './constants/label';

const resources = {
  [enGB]: { messages: enTranslations },
  [frFR]: { messages: frTranslations },
  [de]: { messages: deTranslations },
  [enCanada]: { messages: enCanadaTranslations },
  [frCanada]: { messages: frCanadaTranslations },
};

const init_i18n = i18next.use(initReactI18next);
const i18n = (userLanguage) => {
  init_i18n.init({
    react: {
      wait: true,
    },
    resources: resources,
    lng: userLanguage,
    fallbackLng: enGB,
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
    ns: ["messages"],
    defaultNS: "messages",
    fallbackNS: [],
  });

  return init_i18n;
};

export default i18n;
