import * as paths from "./paths";
import { store } from "../store/index.js";
export const Eurosport = "Eurosport";
export const Max = "Max";
export const EurosportPlayer = "Eurosport Player";
export const DiscoveryPlus = "discovery+";
export const GCN = "Global Cycling Network";
export const GTV = "GolfTV";

export const convenience = "Cancel For Convenience";
export const cause = "Cancel For Cause";
export const addressChange = "Changement d’adresse";
export const situationChange = "Changement de situation familiale";

export const waterGazBill = "facture_eau-gaz-electricite";
export const phoneBill = "facture_telephone";
export const rentReceipt = "quittance-loyer";
export const bankOrCredit = "releve_bancaire-credit";
export const homeInsurance = "declaration-assurance-habitation";
export const pacs = "certificat-pacs";
export const marriage = "certificat-mariage";
export const death = "certificat_deces_livret-famille";
export const administrationRequest = "demande_naissance-depart-enfant";

export const fr = "fr";
export const en = "en";
export const enGB = "en_GB";
export const de = "de";
export const frFR = "fr_FR";
export const frCanada = "fr_canada";
export const enCanada = "en_canada";

export const homeUrls = (folder) => {
    let EurosportUrl, MaxUrl, GcnUrl, EurosportPlayerUrl, DiscoveryPlusUrl, GTVUrl = "";
    switch (folder) {
        case paths.subDomainFrance:
            EurosportUrl = "https://www.eurosport.fr/";
            MaxUrl = "https://www.max.com/";
            GcnUrl = "https://plus.globalcyclingnetwork.com/fr";
            EurosportPlayerUrl = "https://www.eurosportplayer.com/";
            DiscoveryPlusUrl = "https://www.discoveryplus.com";
            GTVUrl = "https://www.golf.tv/";
            break;
        case paths.subDomainCanada:
            EurosportUrl = "https://www.eurosport.com";
            GcnUrl = "https://plus.globalcyclingnetwork.com";
            EurosportPlayerUrl = "https://www.eurosportplayer.com/";
            DiscoveryPlusUrl = "https://www.discoveryplus.com";
            GTVUrl = "https://www.golf.tv/";
            break;
        default:
            EurosportUrl = "https://www.eurosport.de/";
            MaxUrl = "https://www.max.com/";
            GcnUrl = "https://plus.globalcyclingnetwork.com/de";
            EurosportPlayerUrl = "https://www.eurosportplayer.com/";
            DiscoveryPlusUrl = "https://www.discoveryplus.com";
            GTVUrl = "https://www.golf.tv/";
            break;

    }
    return {
        [Eurosport]: EurosportUrl,
        [Max]: MaxUrl,
        [EurosportPlayer]: EurosportPlayerUrl,
        [GCN]: GcnUrl,
        [DiscoveryPlus]: DiscoveryPlusUrl,
        [GTV]: GTVUrl
    }
}

export const pdfLogoPaths =
{
    [Eurosport]: {
        path: "/png/eurosport.png",
        height: "25px",
        width: "179px",
    },
    [Max]: {
        path: "/png/max.png",
        height: "25px",
        width: "90px",
    },
    [EurosportPlayer]: {
        path: "/png/eurosportPlayer.png",
        height: "39px",
        width: "250px",
    },
    [DiscoveryPlus]: {
        path: "/png/dplus.png",
        height: "58px",
        width: "70px",
    },
    [GTV]: {
        path: "/png/gtv.png",
        height: "20px",
        width: "132px",
    },
    [GCN]: {
        path: "/png/gcn.png",
        height: "48px",
        width: "161px",
    }
}

export const helpCenterUrls = (folder) => {
    let EurosportUrl, MaxUrl, GcnUrl, EurosportPlayerUrl, DiscoveryPlusUrl, GTVUrl = "";

    switch (folder) {
        case paths.subDomainFrance:
            MaxUrl = "https://help.max.com/fr/";
            EurosportUrl = "https://help.eurosport.com/hc/fr";
            GcnUrl = "https://help.globalcyclingnetwork.com/hc/en-gb";
            EurosportPlayerUrl = "https://help.eurosport.com/hc/fr";
            DiscoveryPlusUrl = "https://support.discoveryplus.com/hc/en-gb";
            GTVUrl = "https://help.golf.tv/hc/fr";
            break;
        case paths.subDomainCanada:
            MaxUrl = "https://help.max.com/fr/";
            EurosportUrl = "https://help.eurosport.com/hc/fr";
            GcnUrl = "https://help.globalcyclingnetwork.com/hc/en-gb";
            EurosportPlayerUrl = "https://help.eurosport.com/hc/fr";
            DiscoveryPlusUrl = store.getState().form.local === frCanada ? "https://help.discoveryplus.com/hc/fr-ca" : "https://help.discoveryplus.com/hc/en-ca";
            GTVUrl = "https://help.golf.tv/hc/de/";
            break;
        default:
            MaxUrl = "https://help.max.com/fr/";
            EurosportUrl = "https://help.eurosport.com/hc/de";
            GcnUrl = "https://help.globalcyclingnetwork.com/hc/en-gb";
            EurosportPlayerUrl = "https://help.eurosport.com/hc/de";
            DiscoveryPlusUrl = "https://support.discoveryplus.com/hc/de";
            GTVUrl = "https://help.golf.tv/hc/de/";
            break;
    }
    return {
        [Eurosport]: EurosportUrl,
        [Max]: MaxUrl,
        [EurosportPlayer]: EurosportPlayerUrl,
        [GCN]: GcnUrl,
        [DiscoveryPlus]: DiscoveryPlusUrl,
        [GTV]: GTVUrl
    }
}

export const brandDTO = {
    [Eurosport]: "eurosport",
    [Max]: "max",
    [EurosportPlayer]: "eurosportplayer",
    [DiscoveryPlus]: "discovery+",
    [GCN]: "globalcyclingnetwork",
    [GTV]: "golftv"
}

export const cancellationReasonDTO = {
    [convenience]: "convenience",
    [cause]: "cause",
    [addressChange]: "change-address",
    [situationChange]: "change-tax-household"
}

export const cancellationReasonCanadaDTO = {
    [convenience]: "nextBillingDate",
    [cause]: "legitimateReason"
}

export const folderMappingLanguage = {
    [paths.subDomainFrance]: frFR,
    [paths.subDomainFrance + fr]: frFR,
    [paths.subDomainFrance + en]: enGB,
    [paths.subDomainGermany]: de,
    [paths.subDomainGermany + fr]: frFR,
    [paths.subDomainGermany + en]: enGB,
    [paths.subDomainCanada]: frCanada,
    [paths.subDomainCanada + en]: enCanada,
    [paths.subDomainCanada + fr]: frCanada,
}

export const lookupPerCountry =
{
    [paths.subDomainFrance]: { [frFR]: "Français (FR)", [enGB]: "English (GB)", [de]: "Deutsche" },
    [paths.subDomainGermany]: { [frFR]: "Français (FR)", [enGB]: "English (GB)", [de]: "Deutsche" },
    [paths.subDomainCanada]: { [frCanada]: "Français (Canada)", [enCanada]: "English (Canada)" }
}

export const lookup = { [frFR]: "Français (FR)", [enGB]: "English (GB)", [de]: "Deutsche", [frCanada]: "Français (Canada)", [enCanada]: "English (Canada)" }