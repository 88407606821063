import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "reactstrap";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import i18next from "i18next";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
import PdfDocument from "../../../components/Pdf/PdfDocument";
import { getDateTime, getDate } from "../../../helpers/date";
import { homeUrls, pdfLogoPaths, cause } from "../../../constants/label";
import * as paths from "../../../constants/paths";
import { getPageTitle } from "../../../helpers/layout";
import ConfirmationFields from "./confirmationFields";

const Confirmation = (props) => {
    let history = useHistory();

    const item = useSelector((state) => state.form.data);
    const folder = useSelector((state) => state.form.folder);
    const brand = useSelector((state) => state.form.brand);
    const requestStatus = useSelector((state) => state.form.requestStatus);
    const [pdfData, setPdfData] = useState({});

    useEffect(() => {
        if (!requestStatus.status)
            history.push(
                props.location.pathname.replace(
                    paths.type_confirmation,
                    paths.type_form
                )
            );
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (brand) document.title = getPageTitle(brand, paths.type_confirmation);
        // eslint-disable-next-line
    }, [brand]);

    useEffect(() => {
        if (item && item.email) {
            const data = {
                logo: pdfLogoPaths[brand],
                title: i18next.t("Cancellation Request Has Been Sent"),
                rows: [
                    {
                        [i18next.t("Streaming product")]:
                            brand,
                    },
                    {
                        [i18next.t("Date of Submission")]: getDateTime(
                            item.submissionDate,
                            "de-DE"
                        ),
                    },
                    {
                        [i18next.t("Email address used to register your Account")]:
                            item.email,
                    },
                    {
                        [i18next.t("Reason For Cancelling")]: i18next.t(
                            item.cancellationReason
                        ),
                    },
                ]
            };

            if (item.cancellationReason === cause) {
                data.subTitle = i18next.t("We may ask you for more information when we process your cancellation request for cause.");
                data.rows.push({
                    [i18next.t("Date Of Termination")]: item.terminationDate ? getDate(item.terminationDate, "de-DE") : ""
                });
                data.rows.push({
                    [i18next.t("Message")]: item.message
                });
            }

            if (item.attachments && item.attachments.length > 0) {
                data.rows.push({ [i18next.t("Justificative documents")]: item.attachments.map(file => `- ${file.shortFileName} \n`) })
            }

            setPdfData(data);
        }

        // eslint-disable-next-line
    }, [item]);

    const onPrint = (event) => {
        event.preventDefault();
        document.getElementById("anchorPrinter").click();
    };

    return (
        <div className="body">
            <div className="center-content">
                <h1 className="title max-font">
                    {i18next.t("Cancellation Request Has Been Sent")}
                </h1>
                <p className="paragraph paragraph-padding">
                    {i18next.t(
                        "We will email you a copy of your completed cancellation form."
                    )}
                    <br />
                    <br />
                    {i18next.t(
                        "You can also take a screenshot, print, or download for your records."
                    )}
                    {item.cancellationReason === cause &&
                        <>
                            <br />
                            <br />
                            {i18next.t("We may ask you for more information when we process your cancellation request for cause.")}
                        </>
                    }
                </p>
                <Form>
                    <ConfirmationFields item={item} />
                    {item.email && (
                        <div className="div-summary">
                            <BlobProvider document={<PdfDocument data={pdfData} />}>
                                {({ url }) => (
                                    <>
                                        <Button
                                            className="btn-summary"
                                            onClick={(e) => onPrint(e)}
                                            outline
                                            block
                                        >
                                            {" "}
                                            {i18next.t("Print")}{" "}
                                        </Button>
                                        <a
                                            id="anchorPrinter"
                                            style={{ display: "none" }}
                                            href={url}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            Print
                                        </a>
                                    </>
                                )}
                            </BlobProvider>
                            <PDFDownloadLink
                                document={<PdfDocument data={pdfData} />}
                                style={{ textDecoration: "none" }}
                                fileName={
                                    brand +
                                    i18next.t(
                                        "_confirmation-of-receipt-of-your-notice-of-termination"
                                    ) +
                                    ".pdf"
                                }
                            >
                                {({ loading }) =>
                                    loading ? (
                                        "Loading document..."
                                    ) : (
                                        <Button
                                            className="btn-summary"
                                            onClick={() => { }}
                                            outline
                                            block
                                        >
                                            {i18next.t("Download")}
                                        </Button>
                                    )
                                }
                            </PDFDownloadLink>

                            <Button
                                type="button"
                                color="primary"
                                block
                                href={homeUrls(folder)[brand]}
                            >
                                {i18next.t("Back To Home")}
                            </Button>
                        </div>
                    )}
                </Form>
            </div>
        </div>
    );
};

export default Confirmation;
