import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { Spinner } from "reactstrap";
import { useSelector } from "react-redux";

const Loader = (props) => {
  const isLoading = useSelector((state) => state.form.isLoading);
  LoadingOverlay.propTypes = undefined;
  return (
    <LoadingOverlay active={isLoading} styles={{
      overlay: (base) => ({
        ...base,
        opacity: 0.5,
        background: '#ffffff'
      })
    }}
      spinner={<Spinner color="secondary" />}>
      {props.children}
    </LoadingOverlay>
  );
};

export default Loader;
