import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import SvgIcon from "@material-ui/core/SvgIcon";
import Fab from "@material-ui/core/Fab";
import {
  Add,
  Edit,
  Delete,
  Navigation,
  Print,
  Save,
  WarningRounded,
  HelpOutlined,
  FileCopyOutlined,
} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import i18next from "i18next";
export { Delete, AttachFile, AddOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > svg": {
      margin: theme.spacing(2),
    },
  },
  iconHover: {
    "&:hover": {
      color: red[800],
    },
  },
}));

export function AddIcon(props) {
  return (
    <Tooltip title="Add">
      <Fab color="primary" aria-label="add" {...props}>
        <Add />
      </Fab>
    </Tooltip>
  );
}

export function EditIcon(props) {
  return (
    <Tooltip title="Edit">
      <Fab size="small" color="extended" aria-label="edit" {...props}>
        <Edit />
      </Fab>
    </Tooltip>
  );
}

export function DeleteIcon(props) {
  return (
    <Tooltip title="Delete">
      <Fab size="small" color="extended" aria-label="delete" {...props}>
        <Delete />
      </Fab>
    </Tooltip>
  );
}

export function NavigationIcon(props) {
  return (
    <Tooltip title="Navigate">
      <Fab size="small" color="primary" aria-label="Navigate" {...props}>
        <Navigation />
      </Fab>
    </Tooltip>
  );
}

export function HelpIcon(props) {
  return (
    <Tooltip {...props}>
      <HelpOutlined />
    </Tooltip>
  );
}

export function PrintIcon(props) {
  return (
    <Tooltip title="Preview & Print">
      <Fab size="small" color="primary" aria-label="Print" {...props}>
        <Print />
      </Fab>
    </Tooltip>
  );
}

export function HomeIcon(props) {
  return (
    <SvgIcon {...props} className={useStyles().iconHover}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

export function WarningIcon(props) {
  return (
    <Tooltip title="Warning">
      <WarningRounded {...props} />
    </Tooltip>
  );
}

export function SaveIcon(props) {
  return (
    <Tooltip title="Save">
      <Fab size="small" color="primary" aria-label="Save" {...props}>
        <Save />
      </Fab>
    </Tooltip>
  );
}

// export function PrintIcon(props) {
//   return (
//     <SvgIcon {...props} color="primary" className={useStyles().iconHover}>
//       <path d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z" />
//     </SvgIcon>
//   );
// }

export function CancelIcon(props) {
  return (
    <SvgIcon {...props} color="secondary" className={useStyles().iconHover}>
      <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
    </SvgIcon>
  );
}

export function ContentCopyIcon(props) {
  return (
    <Tooltip title={i18next.t("Click to copy")}>
      <FileCopyOutlined {...props} />
    </Tooltip>
  );
}
