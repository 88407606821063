import React, { useState } from "react";
import { DeleteIcon } from "../Icons/icons";

import i18next from "i18next";
import { Col, CustomInput, FormFeedback, FormGroup, Label, Row } from "reactstrap";

const Upload = (props) => {
  const { field, title, isRequired, value, placeholder, handleChange, handleDelete, error } = props;
  const [isDirty, setIsDirty] = useState(false);
  return (

    <FormGroup key={field} controlid={field}>
      {title && (<Label for="customFileBrowserLabel"> {isRequired ? title + "*" : title}</Label>)}
      <Row>
        <Col md={handleDelete ? 10 : 12}>
          <CustomInput
            type="file"
            label={value ? value : placeholder}
            id={field ? field : "CustomFileBrowserId"}
            name="customFile"
            onChange={(event) => {
              setIsDirty(true);
              handleChange(field, event.target.files[0])
            }
            }
            required={isRequired}
            invalid={
              (isDirty && props.isRequired && !props.value) || error
            }
          />
          {
            <div
              className="error"
              style={{
                marginTop: "5px",
                textAlign: "left",
              }}
            >
              {error}
            </div>
          }
          <FormFeedback type="invalid">
            {isRequired && !value && i18next.t("required field")}
          </FormFeedback>
        </Col>
        {handleDelete &&
          <Col md={2}>
            <DeleteIcon onClick={(e) => {
              handleDelete(field);
            }} />
          </Col>}
      </Row>
    </FormGroup>
  );
};

export default Upload;
