import { Post } from "./apis/base-api-request";
import {
  convenience,
  brandDTO,
  cancellationReasonDTO,
  cancellationReasonCanadaDTO,
} from "../constants/label";
import { subDomainCanada } from "../constants/paths";
import { store } from "../store/index.js";

const { REACT_APP_FMP_API_URL } = process.env;

const submitFormUrl = "tickets/noram";
const submitFormV2Url = "tickets";
const fileUploadUrl = "attachments";

export const uploadFiles = async (files) => {
  try {
    for (let i = 0; i < files.length; i++) {
      await Post(REACT_APP_FMP_API_URL + fileUploadUrl + "/" + files[i].name,
        files[i].content, undefined,
        undefined,
        { "arkose-token": files[i].token }
      )
    }

    return { response: true, files };
  }
  catch (e) {
    return { response: false };
  }
}

export const submitForm = async ({ data, token }) => {
  await Post(
    store.getState().form.folder === subDomainCanada
      ? REACT_APP_FMP_API_URL + submitFormUrl
      : REACT_APP_FMP_API_URL + submitFormV2Url,
    formViewToformDTO(data),
    undefined,
    undefined,
    { "arkose-token": token }
  );

  return data;
};

const formViewToformDTO = (formView) => {
  if (
    formView.cancellationReason === convenience ||
    !formView.terminationDate === null ||
    isNaN(formView.terminationDate)
  )
    delete formView.terminationDate;

  return {
    ...formView,
    brand: brandDTO[formView.brand],
    cancellationReason:
      store.getState().form.folder === subDomainCanada
        ? cancellationReasonCanadaDTO[formView.cancellationReason]
        : cancellationReasonDTO[formView.cancellationReason],
  };
};
