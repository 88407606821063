export const SET_BRAND = "SET_BRAND";
export const SET_PLATFORM = "SET_PLATFORM";
export const SET_FORM = "SET_FORM";
export const SUBMIT_FORM = "SUBMIT_FORM";
export const SET_PREFILL_DATA = "SET_PREFILL_DATA";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_REQUEST_STATUS = "SET_REQUEST_STATUS";
export const SET_LOADING = "SET_LOADING";
export const SET_FOLDER = "SET_FOLDER";


