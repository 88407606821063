import React from "react";
import i18next from "i18next";

import discoveryplus from "../../assets/logos/dplus.svg";
import { currentYear } from "../../helpers/date";

const Footer = () => {
    const links = [
        {
            key: "termOfUse",
            title: i18next.t("Terms of Use"),
            url: "https://corporate.discovery.com/discoveryplus/legal/terms-of-use/"
        },
        {
            key: "privacyNotice",
            title: i18next.t("Privacy Notice"),
            url: "https://corporate.discovery.com/discoveryplus/legal/privacy"
        },
        {
            key: "legalInformation",
            title: i18next.t("dplus Legal information"),
            url: "https://corporate.discovery.com/discoveryplus/legal/legal-information/"
        },
        {
            key: "modernSlaveryStatement",
            title: i18next.t("dplus Modern Slavery Statement"),
            url: "https://corporate.discovery.com/discoverys-modern-slavery-statement/"
        },
    ];

    return (
        <footer className="discoveryplus-footer" >
            <div className="discoveryplus-styles-footerNav">
                <ul className="discoveryplus-footer-links">
                    {links.map(link => {
                        return <li key={link.key}>
                            <a className="discoveryplus-styles-links" rel="noopener noreferrer" target="_blank" href={link.url}>
                                {link.title}
                            </a>
                        </li>

                    })}
                </ul>
            </div>
            {/* </div> */}
            <div className="scheme  m-scheme--bottom">
                <div className="discoveryplus-styles-footer-panel">
                    <div className="footer-bottom-panel-v2">
                        <ul className="l-social">
                            <li>
                                <a className="discoveryplus-styles-social-links" rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/discoveryplusDE">
                                    <span className="svg-icon" aria-label="facebook">
                                        <svg aria-label="" version="1.1" id="__GxsQ7-D__Layer_1" x="0px" y="0px" viewBox="0 0 9 18" >
                                            <g id="__GxsQ7-D__Symbols">
                                                <g id="__GxsQ7-D__Navigation_x2F_Footer_x2F_Desktop" transform="translate(-72.000000, -135.000000)">
                                                    <g id="__GxsQ7-D__Bottom-Section" transform="translate(0.000000, 104.000000)">
                                                        <path id="__GxsQ7-D__Path" d="M77.8,35.7c0-0.7,0.1-1.1,1.2-1.1h1.5V32h-2.4c-2.8,0-3.8,1.3-3.8,3.6v1.8h-1.8V40h1.8v8h3.6v-8h2.4l0.4-2.7 h-2.7L77.8,35.7L77.8,35.7z"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a className="discoveryplus-styles-social-links" rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/discoveryplusde/">
                                    <span className="svg-icon" aria-label="instagram">
                                        <svg role="img" aria-label="" version="1.1" id="__1EjVEnk__Layer_1"
                                            xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 27 27" >
                                            <g id="__1EjVEnk__Symbols">
                                                <g id="__1EjVEnk__Navigation_x2F_Footer_x2F_Desktop" transform="translate(-257.000000, -133.000000)">
                                                    <g id="__1EjVEnk__Bottom-Section" transform="translate(0.000000, 104.000000)">
                                                        <g id="__1EjVEnk__Social" transform="translate(56.000000, 19.000000)">
                                                            <g id="__1EjVEnk__insta" transform="translate(201.000000, 10.000000)">
                                                                <path id="__1EjVEnk__Shape" d="M21.6,0H5.4C2.4,0,0,2.4,0,5.4v16.2c0,3,2.4,5.4,5.4,5.4h16.2c3,0,5.4-2.4,5.4-5.4V5.4 C27,2.4,24.6,0,21.6,0 M18.9,4.1H23v4.1h-4.1V4.1z M13.5,8.1c3,0,5.4,2.4,5.4,5.4s-2.4,5.4-5.4,5.4s-5.4-2.4-5.4-5.4 S10.5,8.1,13.5,8.1 M24.3,21.6c0,1.5-1.2,2.7-2.7,2.7H5.4c-1.5,0-2.7-1.2-2.7-2.7v-9.5h2.8c-0.1,0.5-0.1,0.9-0.1,1.4 c0,4.5,3.6,8.1,8.1,8.1s8.1-3.6,8.1-8.1c0-0.5-0.1-0.9-0.1-1.4h2.8V21.6L24.3,21.6z">
                                                                </path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                            <title>
                                            </title>
                                        </svg>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a className="discoveryplus-styles-social-links" rel="noopener noreferrer" target="_blank" href="https://twitter.com/discoveryplusDE">
                                    <span className="svg-icon" aria-label="twitter">
                                        <svg role="img" aria-label="" version="1.1" id="__18Jqxdg__Layer_1"
                                            xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 17.4 18" >
                                            <g id="__18Jqxdg__Symbols">
                                                <g id="__18Jqxdg__Navigation_x2F_Footer_x2F_Desktop" transform="translate(-132.000000, -135.000000)">
                                                    <g id="__18Jqxdg__Bottom-Section" transform="translate(0.000000, 104.000000)">
                                                        <path id="__18Jqxdg__Path" d="M149,33.2c-0.7,0.4-1.4,0.7-2.3,0.9c-0.7-0.7-1.6-1.1-2.6-1.1c-2,0-3.6,1.6-3.6,3.5c0,0.3,0,0.5,0.1,0.8 c-3-0.1-5.6-1.6-7.4-3.7c-0.3,0.5-0.5,1.1-0.5,1.8c0,1.2,0.7,2.3,1.6,2.9c-0.6,0-1.1-0.2-1.6-0.4v0c0,1.7,1.2,3.1,2.9,3.4 c-0.3,0.1-0.6,0.1-1,0.1c-0.2,0-0.4,0-0.7,0c0.4,1.4,1.8,2.4,3.4,2.4c-1.2,1-2.7,1.5-4.4,1.5c-0.3,0-0.6,0-0.9,0 c1.6,1,3.4,1.6,5.5,1.6c6.6,0,10.2-5.4,10.2-10c0-0.2,0-0.3,0-0.4c0.7-0.5,1.3-1.1,1.8-1.8c-0.7,0.3-1.3,0.5-2,0.6 C148.1,34.7,148.7,34,149,33.2">
                                                        </path>
                                                    </g>
                                                </g>
                                            </g>
                                            <title>
                                            </title>
                                        </svg>
                                    </span>
                                </a>
                            </li>
                        </ul>

                        <div className="l-legal">
                            <span>{"© " + currentYear() + " " + i18next.t("Warner Bros. Discovery or its subsidiaries and affiliates. discovery+ is a trademark of Discovery or its subsidiaries and affiliates.")}</span>
                        </div>
                        <div className="l-logo">
                            <img src={discoveryplus} alt="" />
                        </div>
                    </div>
                    {/* <div class="section--spacer " style="min-height: 1.5em;"> */}
                    {/* </div> */}
                </div>
            </div>
        </footer>);
}



export default Footer;