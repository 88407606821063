import React from "react";
import { useSelector } from "react-redux";
import { setLanguage } from "../../store/actions/form";
import { dispatch } from "../../store";
import { Navbar } from "reactstrap";
import { lookup, lookupPerCountry } from '../../constants/label';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { subDomainCanada } from "constants/paths";

const Header = () => {
    const locale = useSelector((state) => state.form.local);
    const country = useSelector((state) => state.form.folder);

    return (
        <Navbar className="gcn-header">
            <div className="logo gcn-logo-position">
                <img className="logo-img"
                    id="logo-gcn"
                    alt="logo-gcn" />
            </div>
            {country === subDomainCanada && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flex: "1 0 30%",
                    }}
                >
                    <DropdownButton id="dropdown-item-button" title={lookup[locale]}>
                        {lookupPerCountry[country] && Object.keys(lookupPerCountry[country]).map((key) =>
                            <Dropdown.Item size="sm" as="button" key={key} onClick={() => dispatch(setLanguage(key))}>{lookupPerCountry[country][key]}</Dropdown.Item>
                        )}
                    </DropdownButton>
                </div>)}
        </Navbar >
    );
};

export default Header;
