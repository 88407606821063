import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import Text from "../../../components/Forms/Inputs/text";
import Description from "../../../components/Forms/Inputs/description";
import { Button } from "reactstrap";
import i18next from "i18next";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
import PdfDocument from "../../../components/Pdf/PdfDocument";
import { getDateTime, getDate } from "../../../helpers/date";
import { homeUrls, pdfLogoPaths, cause } from "../../../constants/label";
import * as paths from "../../../constants/paths";
import { getPageTitle } from "../../../helpers/layout";

const Confirmation = (props) => {
  let history = useHistory();

  const item = useSelector((state) => state.form.data);
  const folder = useSelector((state) => state.form.folder);
  const brand = useSelector((state) => state.form.brand);
  const requestStatus = useSelector((state) => state.form.requestStatus);
  const [pdfData, setPdfData] = useState({});

  useEffect(() => {
    if (!requestStatus.status)
      history.push(
        props.location.pathname.replace(
          paths.type_confirmation,
          paths.type_form
        )
      );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (brand) document.title = getPageTitle(brand, paths.type_confirmation);
    // eslint-disable-next-line
  }, [brand]);

  useEffect(() => {
    if (item && item.email) {
      const data = {
        logo: pdfLogoPaths[brand],
        title: i18next.t("Cancellation Request Has Been Sent"),
        rows: [
          {
            [i18next.t("Streaming product")]:
              brand,
          },
          {
            [i18next.t("Date of Submission")]: getDateTime(
              item.submissionDate,
              "de-DE"
            ),
          },
          {
            [i18next.t("Email address used to register your Account")]:
              item.email,
          },
          {
            [i18next.t("Reason For Cancelling")]: i18next.t(
              item.cancellationReason
            ),
          },
        ],
      };

      if (item.cancellationReason === cause) {
        data.rows.push(
          {
            [i18next.t("Date Of Termination")]: item.terminationDate
              ? getDate(item.terminationDate, "de-DE")
              : "",
          },
          {
            [i18next.t("Message")]: item.message,
          }
        );
      }

      setPdfData(data);
    }

    // eslint-disable-next-line
  }, [item]);

  const onPrint = (event) => {
    event.preventDefault();
    document.getElementById("anchorPrinter").click();
  };

  return (
    <div className="body">
      <div className="center-content">
        <h1 className="title">
          {i18next.t("Cancellation Request Has Been Sent")}
        </h1>
        <p className="paragraph paragraph-padding">
          {i18next.t(
            "We will email you a copy of your completed cancellation form."
          )}
          <br />
          <br />
          {i18next.t(
            "You can also take a screenshot, print, or download for your records."
          )}
        </p>
        <Form>
          <Row>
            <Col md={12}>
              <Text
                field={"email"}
                title={i18next.t("Email address used to register your Account")}
                value={item.email}
                isRequired={false}
                isReadOnly={true}
                placeholder={i18next.t("example")}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Text
                field={"cancellationReason"}
                title={i18next.t("Reason For Cancelling")}
                value={i18next.t(item.cancellationReason)}
                isRequired={false}
                isReadOnly={true}
              />
            </Col>
          </Row>
          {item.cancellationReason === cause && (
            <>
              <Row>
                <Col md={12}>
                  <Text
                    field={"terminationDate"}
                    title={i18next.t("Date Of Termination")}
                    value={
                      item.terminationDate
                        ? getDate(item.terminationDate, "de-DE")
                        : item.terminationDate
                    }
                    isRequired={false}
                    isReadOnly={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Description
                    field={"message"}
                    title={i18next.t("Message")}
                    value={item.message}
                    isRequired={false}
                    isReadOnly={true}
                  // placeholder={i18next.t("Please provide detail on your reason for cancelling. Do not share any sensitive information on this form like passwords or payment details. If you wish to manage your subscription or your Account in any other way, please visit our Help Centre for further information.")}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col md={12}>
              <Text
                field={"submissionDate"}
                title={i18next.t("Date of Submission")}
                value={
                  item.submissionDate
                    ? getDateTime(item.submissionDate, "de-DE")
                    : item.submissionDate
                }
                isRequired={false}
                isReadOnly={true}
              />
            </Col>
          </Row>
          {item.email && (
            <div className="div-summary">
              <BlobProvider document={<PdfDocument data={pdfData} />}>
                {({ url }) => (
                  <>
                    <Button
                      className="btn-summary"
                      onClick={(e) => onPrint(e)}
                      outline
                      block
                    >
                      {" "}
                      {i18next.t("Print")}{" "}
                    </Button>
                    <a
                      id="anchorPrinter"
                      style={{ display: "none" }}
                      href={url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Print
                    </a>
                  </>
                )}
              </BlobProvider>
              <PDFDownloadLink
                document={<PdfDocument data={pdfData} />}
                style={{ textDecoration: "none" }}
                fileName={
                  brand +
                  i18next.t(
                    "_confirmation-of-receipt-of-your-notice-of-termination"
                  ) +
                  ".pdf"
                }
              >
                {({ loading }) =>
                  loading ? (
                    "Loading document..."
                  ) : (
                    <Button
                      className="btn-summary"
                      onClick={() => { }}
                      outline
                      block
                    >
                      {i18next.t("Download")}
                    </Button>
                  )
                }
              </PDFDownloadLink>

              <Button
                type="button"
                color="primary"
                block
                href={homeUrls(folder)[brand]}
              >
                {i18next.t("Back To Home")}
              </Button>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default Confirmation;
