import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { EDialogType } from "../../enums/EDialogType.js";

const Dialog = (props) => {
  let { title, size, component, type, handleClose, footer, style } = props;
  let centered = false;
  switch (type) {
    case EDialogType.large:
      size = "lg";
      centered = true;
      break;
    case EDialogType.xlarge:
      size = "xl";
      centered = true;
      break;
    default:
      size = EDialogType.medium;
  }

  return (
    <Modal
      isOpen={true}
      centered={centered}
      size={size}
      toggle={() => handleClose()}
    >
      {title && <ModalHeader toggle={() => handleClose()}>{title}</ModalHeader>}
      {component && <ModalBody style={style}>{component}</ModalBody>}
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </Modal>
  );
};

export default Dialog;
