import {
  SET_BRAND,
  SET_REQUEST_STATUS,
  SET_PLATFORM,
  SET_LANGUAGE,
  SET_FORM,
  SET_PREFILL_DATA,
  SUBMIT_FORM,
  SET_LOADING, SET_FOLDER
} from "../../constants/actions-types";

export const setBrand = (data) => ({
  type: SET_BRAND,
  payload: data,
});
export const setPlatform = (data) => ({
  type: SET_PLATFORM,
  payload: data,
});
export const setRequestStatus = (data) => ({
  type: SET_REQUEST_STATUS,
  payload: data,
});
export const setForm = (data) => ({
  type: SET_FORM,
  payload: data,
});
export const submitForm = (data) => ({
  type: SUBMIT_FORM,
  payload: data,
});

export const setPrefillData = (data) => ({
  type: SET_PREFILL_DATA,
  payload: data,
});

export const setLanguage = (data) => ({
  type: SET_LANGUAGE,
  payload: data,
});
export const setFolder = (data) => ({
  type: SET_FOLDER,
  payload: data,
});
export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data,
});