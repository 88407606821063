import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../header";
// import Footer from "./footer.js";
import { Route, Switch } from "react-router-dom";
import { setBrand, setPlatform } from "../../../store/actions/form";
import { getFavicon, getPlatform } from "../../../helpers/layout";
import { maxRoutes } from "../../../routes";
import * as paths from "../../../constants/paths";
import { Max } from "../../../constants/label";

const Main = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const favicon = getFavicon();
        favicon.href = "/faviconMax.ico";

        document.body.style.backgroundColor = '#f5f5f5';

        dispatch(setBrand(Max));
        dispatch(setPlatform(getPlatform(window.location.href)));
        // eslint-disable-next-line
    }, []);

    return (
        <div className="max-font">
            <Header />
            <Switch>
                {maxRoutes(paths.subDomainFrance).map((route, key) =>
                    <Route
                        path={route.paths}
                        component={route.component}
                        key={key}
                    />)}
            </Switch>
        </div>
    );
};

export default Main;
