import Clipboard from "components/Forms/Others/clipboard";
import React from "react";
import i18next from "i18next";

const DocumentSubmissionAddress = () => {
  const submissionAddress =
    "Eurosport, FAO Customer Service, Chiswick Park Building 2, 566 Chiswick High Road, London, W4 5YB,  United Kingdom";
  return (
    <div className="center-content">
      <p className="paragraph paragraph-padding">
        {i18next.t(
          "You can also send us your documents to the following address"
        )}
      </p>
      <Clipboard value={submissionAddress} style={{ fontSize: "16px" }}>
        <p>
          Eurosport
          <br />
          FAO Customer Service,
          <br />
          Chiswick Park Building 2,
          <br />
          566 Chiswick High Road,
          <br />
          London,
          <br />
          W4 5YB,
          <br />
          United Kingdom
        </p>
      </Clipboard>
    </div>
  );
};

export default DocumentSubmissionAddress;
